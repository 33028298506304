import * as index from "./index.js";

declare global {
    interface HTMLElementTagNameMap {
        "app-location-add-dialog": index.LocationAddDialog;
        "app-location-remove-dialog": index.LocationRemoveDialog;
        "app-location-edit-dialog": index.LocationEditDialog;
    }
}

customElements.define("app-location-add-dialog", index.LocationAddDialog);
customElements.define("app-location-remove-dialog", index.LocationRemoveDialog);
customElements.define("app-location-edit-dialog", index.LocationEditDialog);

