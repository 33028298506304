import { html } from "lit";
import { property } from "lit/decorators.js";
import { ComponentBase } from "../../base.js";

export class MachineAddDialog extends ComponentBase {
    @property({ type: String, reflect: true })
    location!: string

    render() {
        return html`
<h1>Create machine</h1>
<app-machine-add location=${this.location}></app-machine-add>
`;
    }
}
