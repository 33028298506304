import "./actions/main.js";
import "./fields/main.js";
import * as index from "./index.js";

declare global {
    interface HTMLElementTagNameMap {
        "app-icon": index.IconComponent;
        "app-scrollable": index.ScrollableComponent;
        "app-action-bar": index.ActionBarComponent;
        "app-two-columns": index.TwoColumnsComponent;
        "app-loading": index.LoadingComponent;
        "app-not-found": index.NotFoundComponent;
    }
}

customElements.define("app-icon", index.IconComponent);
customElements.define("app-scrollable", index.ScrollableComponent);
customElements.define("app-action-bar", index.ActionBarComponent);
customElements.define("app-two-columns", index.TwoColumnsComponent);
customElements.define("app-loading", index.LoadingComponent);
customElements.define("app-not-found", index.NotFoundComponent);
