import { html } from "lit";
import { property } from "lit/decorators.js";
import { FieldComponentBase } from "./field-component-base.js";

export class BooleanField extends FieldComponentBase<boolean> {

    @property({ type: Boolean })
    value = false;

    renderField(value: boolean) {
        return html`<input type="checkbox" ?checked=${value} />`;
    }

    connectedCallback() {
        super.connectedCallback();

        this.renderRoot.addEventListener("change", this.handleChange);
    }

    disconnectedCallback() {
        super.disconnectedCallback();

        this.renderRoot.removeEventListener("change", this.handleChange);
    }

    handleChange = (event: Event) => {
        const target = event.target as HTMLInputElement;
        try {
            this.value = target.checked;

            const valueEvent = new Event("value", { bubbles: true, composed: true });
            this.dispatchEvent(valueEvent);
        }
        catch (error) {
            const errorEvent = new Event("error", { bubbles: true, composed: true });
            this.dispatchEvent(errorEvent);
        }

        this.touch();
    }

}
