import { html, nothing } from "lit";
import { state } from "lit/decorators.js";
import * as application from "../application/index.js";
import { ComponentBase } from "./base.js";

export class RootComponent extends ComponentBase {
    static styles = [];

    @state()
    routeElement?: HTMLElement = this.getRouteElement();

    @state()
    dialogElement?: HTMLElement = this.getDialogElement()

    getRouteElement() {
        const info = application.getRoute();
        if (info == null) return;

        const element = this.ownerDocument.createElement("app-" + info.name + "-route");
        Object.entries(info.parameters).
            forEach(([key, value]) => element.setAttribute(key, value));

        return element;
    }

    getDialogElement() {
        const info = application.getDialog();
        if (info == null) return;

        const element = this.ownerDocument.createElement("app-" + info.name + "-dialog");
        Object.entries(info.parameters).
            forEach(([key, value]) => element.setAttribute(key, value));

        return element;
    }

    render() {
        const { routeElement, dialogElement } = this;

        return html`
<app-layout ?dialog=${dialogElement != null}>
    <div>
        ${routeElement}
    </div>

    <div slot="dialog">        
        ${dialogElement ? html`<app-dialog>${dialogElement}</app-dialog>` : nothing}
    </div>
</app-layout>
`;
    }

    connectedCallback() {
        super.connectedCallback();

        window.addEventListener("popstate", this.handlePopState);
        window.addEventListener("navigate", this.handleNavigate);
    }

    disconnectedCallback() {
        super.disconnectedCallback();

        window.removeEventListener("popstate", this.handlePopState);
        window.removeEventListener("navigate", this.handleNavigate);
    }

    private handlePopState = () => {
        this.routeElement = this.getRouteElement();
        this.dialogElement = this.getDialogElement();
    }

    private handleNavigate = () => {
        this.routeElement = this.getRouteElement();
        this.dialogElement = this.getDialogElement();
    }
}
