import * as index from "./index.js";

declare global {
    interface HTMLElementTagNameMap {
        "app-provider-list": index.ProviderListComponent;
    }
}

customElements.define("app-provider-list", index.ProviderListComponent);

