import assert from "assert";
import { CSSResult, ReactiveController, ReactiveElement } from "lit";

const counters = new WeakMap<CSSResult, number>();
const styleElements = new WeakMap<CSSResult, HTMLStyleElement>();

export class DocumentStyleController implements ReactiveController {

    constructor(
        private readonly host: ReactiveElement,
        private readonly style: CSSResult,
    ) {
        host.addController(this);
    }

    hostConnected() {
        const document = this.host.ownerDocument;
        assert(window.document === document);
        const counter = counters.get(this.style) ?? 0;
        const counterNext = counter + 1;
        counters.set(this.style, counterNext);

        if (counter === 0) {
            const styleElement = document.createElement("style");
            styleElement.textContent = this.style.cssText;
            styleElements.set(this.style, styleElement);
            document.head.appendChild(styleElement);
        }
    }

    hostDisconnected() {
        const document = this.host.ownerDocument;
        assert(window.document === document);
        const counter = counters.get(this.style) ?? 0;
        const counterNext = counter - 1;
        assert(counterNext >= 0);
        counters.set(this.style, counterNext);

        if (counterNext === 0) {
            const styleElement = styleElements.get(this.style);
            assert(styleElement != null);
            document.head.removeChild(styleElement);
        }
    }

}
