import * as index from "./index.js";

declare global {
    interface HTMLElementTagNameMap {
        "app-machine-list": index.MachineListComponent;
        "app-machine-remove": index.MachineRemoveComponent;
        "app-machine-add": index.MachineAddComponent;
        "app-machine-provision": index.MachineProvisionComponent;
        "app-machine-edit": index.MachineEditComponent;
    }
}

customElements.define("app-machine-list", index.MachineListComponent);
customElements.define("app-machine-remove", index.MachineRemoveComponent);
customElements.define("app-machine-add", index.MachineAddComponent);
customElements.define("app-machine-provision", index.MachineProvisionComponent);
customElements.define("app-machine-edit", index.MachineEditComponent);

