import { html } from "lit";
import { property } from "lit/decorators.js";
import { formatInterval, parseInterval } from "../../../utils/interval.js";
import { InputFieldBase } from "./input-field-base.js";

export class IntervalField extends InputFieldBase<number> {

    @property({ type: Number })
    value?: number;

    renderInput(value: string) {
        const { required } = this;

        return html`<input type="text" .value=${value} ?required=${required} />`;
    }

    parseValue(value: string) {
        if (value.trim() === "") return undefined;

        return parseInterval(value);
    }

    formatValue(value: number | undefined) {
        if (value == null) return "";

        return formatInterval(value);
    }
}
