import assert from "assert";
import { property } from "lit/decorators.js";
import * as application from "../../../application/index.js";
import { ActionComponentBase } from "./base.js";

export class DialogActionComponent extends ActionComponentBase {
    @property({ type: String, reflect: true })
    dialogName?: string

    @property({ type: Object, reflect: true })
    dialogParameters?: Record<string, string>

    connectedCallback() {
        super.connectedCallback();

        this.addEventListener("action", this.handleAction);
    }

    disconnectedCallback() {
        super.disconnectedCallback();

        this.removeEventListener("action", this.handleAction);
    }

    private handleAction = (event: Event) => {
        event.preventDefault();
        event.stopPropagation();

        assert(this.dialogName, "expected dialogName");

        application.pushDialog(this.dialogName, this.dialogParameters);
    }

}
