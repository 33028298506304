import * as index from "./index.js";

declare global {
    interface HTMLElementTagNameMap {
        "app-organization-list": index.OrganizationListComponent;
        "app-organization-header": index.OrganizationHeaderComponent;
        "app-organization-detail": index.OrganizationDetailComponent;
        "app-organization-archive": index.OrganizationArchiveComponent;
        "app-organization-create": index.OrganizationCreateComponent;
        "app-organization-edit": index.OrganizationEditComponent;
    }
}

customElements.define("app-organization-list", index.OrganizationListComponent);
customElements.define("app-organization-header", index.OrganizationHeaderComponent);
customElements.define("app-organization-detail", index.OrganizationDetailComponent);
customElements.define("app-organization-archive", index.OrganizationArchiveComponent);
customElements.define("app-organization-create", index.OrganizationCreateComponent);
customElements.define("app-organization-edit", index.OrganizationEditComponent);

