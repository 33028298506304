import "./dialogs/main.js";
import * as index from "./index.js";
import "./molecules/main.js";
import "./organisms/main.js";
import "./routes/main.js";
import "./templates/main.js";

declare global {
    interface HTMLElementTagNameMap {
        "app-root": index.RootComponent;
    }
}

customElements.define("app-root", index.RootComponent);

