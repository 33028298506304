import * as adminApi from "@gameye-managed/admin-api-spec";
import assert from "assert";
import { html, nothing, PropertyValues } from "lit";
import { property } from "lit/decorators.js";
import { BusyController, FormController, QueryValueController } from "../../../controllers/index.js";
import * as q from "../../../queries/index.js";
import { FieldValidators, isIterableEmpty } from "../../../utils/index.js";
import { ComponentBase } from "../../base.js";

type Model =
    adminApi.OrganizationUpdateCommandApplicationJsonRequestBodyOrganizationSchema;

const members = [
    "name",
] as Array<keyof Model>;

const fieldValidators = {
    // eslint-disable-next-line max-len
    ...adminApi.validateOrganizationUpdateCommandApplicationJsonRequestBodyOrganizationSchema.properties,
} as FieldValidators<Model>;

function hasError(model: Partial<Model>, member: keyof Model): boolean {
    const fieldValidator = fieldValidators[member];
    return model[member] != null && !isIterableEmpty(fieldValidator(model[member]));
}

export class OrganizationEditComponent extends ComponentBase {
    @property({ type: String, reflect: true })
    organization!: string

    private busy = new BusyController(this);

    private form = new FormController<Model>(
        this,
        members,
        hasError,
    )

    private queryValue = new QueryValueController(
        this,
        applicationContext.queries.organization,
        applicationSettings.linger,
        q.selectOrganizationItem,
        () => [this.organization] as const,
    );

    protected getModel() {
        if (this.queryValue.value == null) return;

        const {
            name,
        } = this.queryValue.value;

        const model = {
            name,
        };

        return model;
    }

    render() {
        const { isBusy } = this.busy;
        const { model, errors } = this.form;

        if (this.queryValue.loading) return html`<app-loading></app-loading>`;
        if (model == null || errors == null) return nothing;

        return html`
<fieldset>

<app-text-field
    title="Name"
    data-member="name"
    .value=${model.name}
    ?error=${errors.name}
    trim
    required
></app-text-field>

</fieldset>

<app-action-bar>
<app-clickable-action type="primary" ?disabled=${isBusy} @action=${this.handleSubmit}>
    <app-icon type="thumb_up"></app-icon>
    Ok
</app-clickable-action>
<app-clickable-action type="secondary" ?disabled=${isBusy} @action=${this.handleCancel}>
    <app-icon type="undo"></app-icon>
    Cancel
</app-clickable-action>
</app-action-bar>
`;
    }

    private handleSubmit = this.busy.wrap(async () => {
        const { model } = this.form;
        assert(model);

        this.form.touch();
        if (!this.form.isValid()) return;

        const result = await applicationContext.services.backend.organizationUpdateCommand({
            parameters: {},
            entity: () => {
                return {
                    // eslint-disable-next-line max-len
                    organization: {
                        id: this.organization,
                        ...model,
                        // eslint-disable-next-line max-len
                    } as adminApi.OrganizationUpdateCommandApplicationJsonRequestBodyOrganizationSchema,
                };
            },
        });

        assert(result.status === 204);

        history.back();
    })

    private handleCancel = async (event: Event) => {
        history.back();
    }

    update(changedProperties: PropertyValues) {
        super.update(changedProperties);

        if (this.form.model != null) return;

        const model = this.getModel();
        if (model == null) return;

        this.form.setModel(model);
    }
}

