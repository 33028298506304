import { html } from "lit";
import { ComponentBase } from "../../base.js";

export class LocationListRouteComponent extends ComponentBase {

    render() {
        return html`
<h1>Locations</h1>
<app-location-list></app-location-list>
`;
    }
}

