import * as index from "./index.js";

declare global {
    interface HTMLElementTagNameMap {
        "app-organization-location-list": index.OrganizationLocationListComponent;
        "app-organization-locations-edit": index.OrganizationLocationEditComponent;
    }
}

customElements.define("app-organization-location-list", index.OrganizationLocationListComponent);
customElements.define("app-organization-locations-edit", index.OrganizationLocationEditComponent);

