import { html } from "lit";
import { repeat } from "lit/directives/repeat.js";
import { QueryStateController } from "../../../controllers/index.js";
import * as q from "../../../queries/index.js";
import { ComponentBase } from "../../base.js";

export class OrganizationListComponent extends ComponentBase {

    private queryState = new QueryStateController(
        this,
        applicationContext.queries.organization,
        applicationSettings.linger,
        () => {
            return [] as const;
        },
    )

    render() {
        const organizationState = this.queryState.state;

        if (!organizationState) return html`<app-loading></app-loading>`;

        const list = q.selectOrganizationList(organizationState);
        if (list.length === 0) return html`

<p>
    No organization found!
</p>

<app-action-bar>
<app-dialog-action type="primary" dialogName="organization-create">
    <app-icon type="add"></app-icon>
    Create your first organization.
</app-dialog-action>
</app-action-bar>
`;

        return html`
<app-action-bar>
<app-dialog-action dialogName="organization-create">
    <app-icon type="add"></app-icon>
    Add organization
</app-dialog-action>
</app-action-bar>

<app-scrollable>

<table>
<thead>
    <tr>
        <th>ID</th>
        <th>Name</th>
        <th></th>
    </tr>
</thead>

<tbody>
${repeat(list, item => item.organization, item => html`
<tr>
    <td>${item.organization}</td>
    <td>${item.name}</td>
    <td>
        <app-action-bar inline>
        <app-route-action type="primary" routeName="organization-detail" .routeParameters=${{ organization: item.organization }}>
            <app-icon type="description"></app-icon>
        </app-route-action>

        <app-dialog-action type="secondary" dialogName="organization-edit" .dialogParameters=${{ organization: item.organization }}>
            <app-icon type="edit"></app-icon>
        </app-dialog-action>

        <app-dialog-action type="secondary" dialogName="organization-archive" .dialogParameters=${{ organization: item.organization }}>
            <app-icon type="delete"></app-icon>
        </app-dialog-action>
        </app-action-bar>
    </td>
</tr>
`)}
</tbody>
</table>

</app-scrollable>
`;
    }

}
