import { html } from "lit";
import { property } from "lit/decorators.js";
import { ComponentBase } from "../../base.js";

export class SessionLogsRouteComponent extends ComponentBase {

    @property({ type: String, reflect: true })
    organization!: string;

    @property({ type: String, reflect: true })
    session!: string;

    render() {
        return html`
<h1>Session ${this.session} logs</h1>

<app-session-header organization=${this.organization} session=${this.session}></app-session-header>
<app-session-logs organization=${this.organization} session=${this.session}></app-session-logs>
`;
    }
}

