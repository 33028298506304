import "./image/main.js";
import * as index from "./index.js";
import "./location/main.js";
import "./organization/main.js";
import "./provider/main.js";
import "./region/main.js";
import "./session/main.js";

declare global {
    interface HTMLElementTagNameMap {
        "app-home-route": index.HomeRouteComponent;
    }
}

customElements.define("app-home-route", index.HomeRouteComponent);

