import assert from "assert";
import { html } from "lit";
import { property } from "lit/decorators.js";
import * as application from "../../../application/index.js";
import { ComponentBase } from "../../base.js";

export class LocationHeaderComponent extends ComponentBase {

    @property({ type: String, reflect: true })
    location?: string;

    render() {
        const { location } = this;
        assert(location);

        const route = application.getRoute();
        assert(route);

        return html`
<app-action-bar>
    <app-route-action
        type="tertiary"
        replace
        ?active=${route.name === "location-detail"} 
        routeName="location-detail"
        .routeParameters=${{ location }}
    >
    <app-icon type="description"></app-icon>
    Detail
    </app-route-action>

    <app-route-action
        type="tertiary"
        replace
        ?active=${route.name === "location-machines"} 
        routeName="location-machines"
        .routeParameters=${{ location }}
    >
    <app-icon type="library_books"></app-icon>
    Machines
    </app-route-action>

    <app-route-action
        type="tertiary"
        replace
        ?active=${route.name === "location-images"} 
        routeName="location-images"
        .routeParameters=${{ location }}
    >
    <app-icon type="library_books"></app-icon>
    Images
    </app-route-action>
</app-action-bar>

`;
    }
}
