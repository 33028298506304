import assert from "assert";
import { html } from "lit";
import { property } from "lit/decorators.js";
import { QueryStateController } from "../../../controllers/index.js";
import * as q from "../../../queries/index.js";
import { ComponentBase } from "../../base.js";

export class OrganizationDetailComponent extends ComponentBase {
    @property({ type: String, reflect: true })
    organization?: string

    private queryState = new QueryStateController(
        this,
        applicationContext.queries.organization,
        applicationSettings.linger,
        () => {
            return [] as const;
        },
    )

    render() {
        const organizationState = this.queryState.state;

        if (!organizationState) return html`<app-loading></app-loading>`;

        assert(this.organization);

        const item = q.selectOrganizationItem(organizationState, this.organization);
        if (item == null) return html`<p>Not found!</p>`;

        return html`
<app-action-bar>
    <app-dialog-action dialogName="organization-edit" .dialogParameters=${{ organization: item.organization }}>
        <app-icon type="edit"></app-icon>
        Edit this organization
    </app-dialog-action>
</app-action-bar>

<dl>
    <dt>ID</dt>
    <dd>${item.organization}</dd>

    <dt>Name</dt>
    <dd>${item.name}</dd>
</dl>
`;
    }
}
