import * as adminApi from "@gameye-managed/admin-api-spec";
import assert from "assert";
import { html, nothing, PropertyValues } from "lit";
import { property } from "lit/decorators.js";
import { BusyController, FormController, QueryValueController } from "../../../controllers/index.js";
import { queryValue } from "../../../directives/index.js";
import * as q from "../../../queries/index.js";
import { FieldValidators, isIterableEmpty } from "../../../utils/index.js";
import { ComponentBase } from "../../base.js";

type Model = adminApi.LocationUpdateCommandApplicationJsonRequestBodyLocationSchema

const members = [
    "name",
    "geoLocation",
    "provider",
    "config",
    "limits",
    "advantages",
] as Array<keyof Model>;

const fieldValidators = {
    ...adminApi.validateLocationUpdateCommandApplicationJsonRequestBodyLocationSchema.properties,
} as FieldValidators<Model>;

function hasError(model: Partial<Model>, member: keyof Model): boolean {
    const fieldValidator = fieldValidators[member];
    return model[member] != null && !isIterableEmpty(fieldValidator(model[member]));
}

export class LocationEditComponent extends ComponentBase {
    @property({ type: String, reflect: true })
    location!: string

    private busy = new BusyController(this);

    private form = new FormController<Model>(
        this,
        members,
        hasError,
    )

    private queryValue = new QueryValueController(
        this,
        applicationContext.queries.location,
        applicationSettings.linger,
        q.selectLocationItem,
        () => [this.location] as const,
    );

    protected getModel() {
        if (this.queryValue.value == null) return;

        const {
            name,
            geoLocation,
            provider,
            config,
            limits,
            advantages,
            dynamic,
            machineLingerInterval,
            machineReadyInterval,
        } = this.queryValue.value;

        const model = {
            name,
            geoLocation,
            provider,
            config,
            limits,
            advantages,
            dynamic,
            machineLingerInterval,
            machineReadyInterval,
        };

        return model;
    }

    render() {
        const { isBusy } = this.busy;
        const { model, errors } = this.form;

        if (this.queryValue.loading) return html`<app-loading></app-loading>`;
        if (model == null || errors == null) return nothing;

        return html`
<fieldset>

<app-text-field
    title="Name"
    data-member="name"
    .value=${model.name}
    ?error=${errors.name}
    trim
    required
></app-text-field>

<app-select-field
    title="Region"
    data-member="geoLocation"
    .value=${model.geoLocation}
    .options=${queryValue(applicationContext.queries.region, q.selectRegionOptions)}
    ?error=${errors.geoLocation}
    required
></app-select-field>

<app-text-field
    title="Provider"
    data-member="provider"
    .value=${model.provider}
    ?error=${errors.provider}
    trim
    required
></app-text-field>

<app-boolean-field
    title="Dynamic"
    data-member="dynamic"
    .value=${model.dynamic ?? false}
    ?error=${errors.dynamic}
></app-boolean-field>

<app-interval-field
    title="Machine Ready Interval"
    data-member="machineReadyInterval"
    .value=${model.machineReadyInterval}
    ?error=${errors.machineReadyInterval}
></app-interval-field>

<app-interval-field
    title="Machine Linger Interval"
    data-member="machineLingerInterval"
    .value=${model.machineLingerInterval}
    ?error=${errors.machineLingerInterval}
></app-interval-field>

<app-json-field
    title="Config"
    data-member="config"
    .value=${model.config}
    ?error=${errors.config}
    trim
    required
></app-json-field>

<app-json-field
    title="Limits"
    data-member="limits"
    .value=${model.limits}
    ?error=${errors.limits}
    trim
    required
></app-json-field>

<app-json-field
    title="Advantages"
    data-member="advantages"
    .value=${model.advantages}
    ?error=${errors.advantages}
    trim
    required
></app-json-field>

</fieldset>

<app-action-bar>
<app-clickable-action type="primary" ?disabled=${isBusy} @action=${this.handleSubmit}>
    <app-icon type="thumb_up"></app-icon>
    Ok
</app-clickable-action>
<app-clickable-action type="secondary" ?disabled=${isBusy} @action=${this.handleCancel}>
    <app-icon type="undo"></app-icon>
    Cancel
</app-clickable-action>
</app-action-bar>
`;
    }

    private handleSubmit = this.busy.wrap(async () => {
        const { model } = this.form;
        assert(model);

        this.form.touch();
        if (!this.form.isValid()) return;

        const {
            name,
            geoLocation,
            provider,
            config,
            limits,
            advantages,
            dynamic,
            machineLingerInterval,
            machineReadyInterval,
        } = model as Model;

        const result = await applicationContext.services.backend.locationUpdateCommand({
            parameters: {},
            entity: () => {
                return {
                    // eslint-disable-next-line max-len
                    location: {
                        id: this.location,
                        name,
                        geoLocation,
                        provider,
                        config,
                        limits,
                        advantages,
                        dynamic,
                        machineLingerInterval,
                        machineReadyInterval,
                    },
                };
            },
        });

        assert(result.status === 204);

        history.back();
    })

    private handleCancel = async (event: Event) => {
        history.back();
    }

    update(changedProperties: PropertyValues) {
        super.update(changedProperties);

        if (this.form.model != null) return;

        const model = this.getModel();
        if (model == null) return;

        this.form.setModel(model);
    }
}

