import * as adminApi from "@gameye-managed/admin-api-spec";
import assert from "assert";
import immutable from "immutable";
import { createQueryMemoizer, Query, QueryMemoizer } from "queries-kit";
import * as application from "../application/index.js";
import { setQueryArgCount } from "../utils/index.js";

//#region query

export type ImageOrganizationQueryMemoizer = QueryMemoizer<
    ImageOrganizationQueryState, ImageOrganizationQueryEventUnion, [string]
>

export type ImageOrganizationQuery =
    Query<ImageOrganizationQueryState, ImageOrganizationQueryEventUnion>

export function createImageOrganizationQueryMemoizer(
    services: application.Services,
    settings: application.Settings,
    onError: (error: unknown) => void,
): ImageOrganizationQueryMemoizer {
    const memoizer = createQueryMemoizer({
        retryIntervalBase: settings.retryIntervalBase,
        retryIntervalCap: settings.retryIntervalCap,
        initialState,
        reduce,
        source,
        onError,
    });

    setQueryArgCount(memoizer, 1);

    return memoizer;

    async function* source(
        signal: AbortSignal,
        image: string,
    ) {
        const source = await services.backend.getImageOrganizations({
            parameters: { image },
        });
        assert(source.status === 200);
        yield* source.entities(signal);
    }
}

//#endregion

//#region state / events

export type ImageOrganizationQueryEventUnion =
    adminApi.ImageOrganizationSnapshotSchema |
    adminApi.ImageOrganizationAddedSchema |
    adminApi.ImageOrganizationRemovedSchema;

export type ImageOrganizationQueryEntity = {}

export interface ImageOrganizationQueryState {
    entities: immutable.Map<string, ImageOrganizationQueryEntity>;
}

const initialState: ImageOrganizationQueryState = {
    entities: immutable.Map(),
};

function reduce(
    state: ImageOrganizationQueryState,
    event: ImageOrganizationQueryEventUnion,
): ImageOrganizationQueryState {
    switch (event.type) {
        case "image-organization-snapshot": {
            let { entities } = initialState;
            entities = entities.asMutable();

            for (const { organization } of event.payload) {
                const entity = {};
                entities.set(organization, entity);
            }

            entities.asImmutable();

            return {
                entities,
            };
        }

        case "image-organization-added": {
            let { entities } = state;

            const { organization } = event.payload;
            const entity = {};

            assert(!entities.get(organization));
            entities = entities.set(organization, entity);

            return {
                ...state,
                entities,
            };
        }

        case "image-organization-removed": {
            let { entities } = state;

            const { organization } = event.payload;

            assert(entities.has(organization));
            entities = entities.delete(organization);

            return {
                ...state,
                entities,
            };
        }

    }

}

//#endregion

//#region selectors

export function hasImageOrganization(
    state: ImageOrganizationQueryState,
    organization: string,
) {
    return state.entities.has(organization);
}

//#endregion
