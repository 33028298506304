import { html } from "lit";
import { repeat } from "lit/directives/repeat.js";
import { QueryStateController } from "../../../controllers/index.js";
import * as q from "../../../queries/index.js";
import { ComponentBase } from "../../base.js";

export class ProviderListComponent extends ComponentBase {
    private queryState = new QueryStateController(
        this,
        applicationContext.queries.provider,
        applicationSettings.linger,
        () => {
            return [] as const;
        },
    )

    render() {
        const providerState = this.queryState.state;
        if (!providerState) return html`<app-loading></app-loading>`;

        const list = q.selectProviderList(providerState);
        if (list.length === 0) return html`

<p>
    No providers found!
</p>

<app-action-bar>
<app-dialog-action type="primary" dialogName="provider-add" .dialogParameters=${{ location }}>
    <app-icon type="add"></app-icon>
    Create your first provider.
</app-dialog-action>
</app-action-bar>
`;

        return html`
<app-action-bar>
<app-dialog-action dialogName="provider-add" .dialogParameters=${{ location }}>
    <app-icon type="add"></app-icon>
    Add provider
</app-dialog-action>
</app-action-bar>

<app-scrollable>

<table>
<thead>
    <tr>
        <th>ID</th>
        <th>Name</th>
        <th>Description</th>
        <th>Polling Interval (ms)</th>
        <th></th>
    </tr>
</thead>

<tbody>
${repeat(list, item => item.id, item => html`
<tr>
    <td>${item.id}</td>
    <td>${item.name}</td>
    <td>${item.description}</td>
    <td>${item.pollingInterval}</td>
    <td>
        <app-action-bar inline>
        <app-dialog-action type="secondary" dialogName="provider-edit" .dialogParameters=${{ id: item.id }} >
            <app-icon type="edit"></app-icon>
        </app-dialog-action>
        <app-dialog-action type="secondary" dialogName="provider-delete" .dialogParameters=${{ id: item.id }} >
            <app-icon type="delete"></app-icon>
        </app-dialog-action>
        </app-action-bar>
    </td>
</tr>
`)}
</tbody>
</table>

</app-scrollable>
`;
    }
}
