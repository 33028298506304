import { css, TemplateResult } from "lit";
import { FieldComponentBase } from "./field-component-base.js";

export abstract class InputFieldBase<T> extends FieldComponentBase<T> {
    static styles = [...super.styles, css`
input {
    box-sizing: border-box;
    font: inherit;
    width: 100%;
    margin: 0;
    padding: 0.3em;
    border-width: 1px;
}

textarea {
    box-sizing: border-box;
    font: inherit;
    width: 100%;
    height: 20em;
    margin: 0;
    padding: 0.3em;
    border-width: 1px;
}
`]
    protected abstract parseValue(formattedValue: string): T | undefined;
    protected abstract formatValue(parsedValue: T | undefined): string;
    protected abstract renderInput(formattedValue: string): TemplateResult<1>;

    renderField(value?: T) {
        const formattedValue = this.formatValue(value);
        return this.renderInput(formattedValue);
    }

    connectedCallback() {
        super.connectedCallback();

        this.renderRoot.addEventListener("change", this.handleChange);
        this.renderRoot.addEventListener("input", this.handleInput);
    }

    disconnectedCallback() {
        super.disconnectedCallback();

        this.renderRoot.removeEventListener("change", this.handleChange);
        this.renderRoot.removeEventListener("input", this.handleInput);
    }

    handleChange = (event: Event) => {
        const target = event.target as HTMLInputElement;
        try {
            this.value = this.parseValue(target.value);
            target.value = this.formatValue(this.value);

            const valueEvent = new Event("value", { bubbles: true, composed: true });
            this.dispatchEvent(valueEvent);
        }
        catch (error) {
            const errorEvent = new Event("error", { bubbles: true, composed: true });
            this.dispatchEvent(errorEvent);
        }

        this.touch();
    }

    handleInput = (event: Event) => {
        const target = event.target as HTMLInputElement;
        try {
            this.value = this.parseValue(target.value);

            const valueEvent = new Event("value", { bubbles: true, composed: true });
            this.dispatchEvent(valueEvent);
        }
        catch (error) {
            const errorEvent = new Event("error", { bubbles: true, composed: true });
            this.dispatchEvent(errorEvent);
        }
    }
}
