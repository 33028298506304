import * as index from "./index.js";

declare global {
    interface HTMLElementTagNameMap {
        "app-session-list": index.SessionListComponent;
        "app-session-header": index.SessionHeaderComponent;
        "app-session-detail": index.SessionDetailComponent;
        "app-session-logs": index.SessionLogsComponent;
        "app-session-stop": index.SessionStopComponent;
        "app-session-run": index.SessionRunComponent;
    }
}

customElements.define("app-session-list", index.SessionListComponent);
customElements.define("app-session-header", index.SessionHeaderComponent);
customElements.define("app-session-detail", index.SessionDetailComponent);
customElements.define("app-session-logs", index.SessionLogsComponent);
customElements.define("app-session-stop", index.SessionStopComponent);
customElements.define("app-session-run", index.SessionRunComponent);

