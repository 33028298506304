import { html } from "lit";
import { property } from "lit/decorators.js";
import { ComponentBase } from "../../base.js";

export class ImageLocationsEditDialog extends ComponentBase {
    @property({ type: String, reflect: true })
    image!: string

    render() {
        return html`
<h1>Edit image locations</h1>
<app-image-locations-edit image=${this.image}></app-image-locations-edit>
`;

    }

}

