import { css, html } from "lit";
import { property } from "lit/decorators.js";
import { ComponentBase } from "../../base.js";

export abstract class ActionComponentBase extends ComponentBase {
    @property({ type: Boolean, reflect: true })
    disabled = false

    @property({ type: String, reflect: true })
    type?: "primary" | "secondary" | "tertiary"

    @property({ type: Boolean, reflect: true })
    active = false

    static styles = [css`
:host {
    cursor: pointer;
    box-sizing: border-box;
    color: var(--darkest-color);
    text-transform: uppercase;
    font-style: italic;
    font-family: "Kanit", sans-serif;
    font-weight: normal;
}
:host(:hover) {
    font-weight: bold;
}
:host([active]) {
    font-weight: bold;
    color: var(--lightest-color);
}
:host([disabled]) {
    cursor: not-allowed;
    opacity: 0.5;
}
:host([disabled]:hover) {
    font-weight: normal;
}

:host([type=primary]) {
    background-color: var(--accent-color);
    color: var(--lightest-color);
    padding: var(--button-padding-height) var(--button-padding-width);
    margin: calc(var(--button-padding-height) * -1) 0;
}

:host([type=secondary]) {
    background-color: var(--brand-color);
    color: var(--lightest-color);
    padding: var(--button-padding-height) var(--button-padding-width);
    margin: calc(var(--button-padding-height) * -1) 0;
}

:host([type=tertiary]) {
    background-color: var(--alternative-color);
    color: var(--lightest-color);
    padding: var(--button-padding-height) var(--button-padding-width);
    margin: calc(var(--button-padding-height) * -1) 0;
}

`];

    render() {
        return html`<slot></slot>`;
    }

    connectedCallback() {
        super.connectedCallback();

        this.renderRoot.addEventListener("click", this.handleClick);
    }

    disconnectedCallback() {
        super.disconnectedCallback();

        this.renderRoot.removeEventListener("click", this.handleClick);
    }

    private handleClick = (event: Event) => {
        if (this.disabled) {
            return;
        }

        const executeDefault = this.dispatchActionEvent();
        if (!executeDefault) {
            event.preventDefault();
        }
    }

    protected dispatchActionEvent() {
        const event = new Event(
            "action",
            {
                bubbles: true,
                composed: true,
                cancelable: true,
            },
        );
        const executeDefault = this.dispatchEvent(event);
        return executeDefault;
    }
}
