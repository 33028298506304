import { html } from "lit";
import { ComponentBase } from "../../base.js";

export class OrganizationCreateDialog extends ComponentBase {
    render() {
        return html`
<h1>Create organization</h1>
<app-organization-create></app-organization-create>
`;
    }

}
