import { html } from "lit";
import { repeat } from "lit/directives/repeat.js";
import { QueryStateController } from "../../../controllers/index.js";
import * as q from "../../../queries/index.js";
import { ComponentBase } from "../../base.js";

export class RegionListComponent extends ComponentBase {

    private queryState = new QueryStateController(
        this,
        applicationContext.queries.region,
        applicationSettings.linger,
        () => {
            return [] as const;
        },
    )

    render() {
        const regionState = this.queryState.state;

        if (!regionState) return html`<app-loading></app-loading>`;

        const list = q.selectRegionList(regionState);
        if (list.length === 0) return html`

<p>
    No region found!
</p>
`;

        return html`

<app-action-bar>
    <app-dialog-action dialogName="region-add">
        <app-icon type="add"></app-icon>
        Create region
    </app-dialog-action>
</app-action-bar>

<app-scrollable>

<table>
<thead>
    <tr>
        <th>ID</th>
        <th>Names</th>
        <th>Parents</th>
        <th></th>
    </tr>
</thead>

<tbody>
${repeat(list, item => item.region, item => html`
<tr>
    <td>${item.region}</td>
    <td>${item.names.join(", ")}</td>
    <td>${item.parents.join(", ")}</td>
    <td>
    <app-action-bar inline>
        <app-route-action type="primary" routeName="region-detail" .routeParameters=${{ region: item.region }}>
            <app-icon type="description"></app-icon>
        </app-route-action>

        <app-dialog-action type="secondary" dialogName="region-edit" .dialogParameters=${{ region: item.region }}>
            <app-icon type="edit"></app-icon>
        </app-dialog-action>

        <app-dialog-action type="secondary" dialogName="region-delete" .dialogParameters=${{ region: item.region }}>
            <app-icon type="delete"></app-icon>
        </app-dialog-action>
    </app-action-bar>
    </td>
</tr>
`)}
</tbody>
</table>

</app-scrollable>
`;
    }
}

