import { css, html } from "lit";
import { ComponentBase } from "../base.js";

export class TwoColumnsComponent extends ComponentBase {

    static styles = [css`
:host {
    display: grid;
    grid-template-columns: max-content max-content;
    gap: var(--padding);
    padding: var(--padding);
}

`];

    render() {
        return html`<slot></slot>`;
    }
}
