import * as index from "./index.js";

declare global {
    interface HTMLElementTagNameMap {
        "app-api-key-list": index.ApiKeyListComponent;
        "app-api-key-remove": index.ApiKeyRemoveComponent;
        "app-api-key-add": index.ApiKeyAddComponent;
    }
}

customElements.define("app-api-key-list", index.ApiKeyListComponent);
customElements.define("app-api-key-remove", index.ApiKeyRemoveComponent);
customElements.define("app-api-key-add", index.ApiKeyAddComponent);

