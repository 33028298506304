import * as adminApi from "@gameye-managed/admin-api-spec";
import assert from "assert";
import immutable from "immutable";
import { createQueryMemoizer, Query, QueryMemoizer } from "queries-kit";
import * as application from "../application/index.js";
import { setQueryArgCount } from "../utils/index.js";

//#region query

export type ImageLocationQueryMemoizer = QueryMemoizer<
    ImageLocationQueryState, ImageLocationQueryEventUnion, [string]
>

export type ImageLocationQuery =
    Query<ImageLocationQueryState, ImageLocationQueryEventUnion>

export function createImageLocationQueryMemoizer(
    services: application.Services,
    settings: application.Settings,
    onError: (error: unknown) => void,
): ImageLocationQueryMemoizer {
    const memoizer = createQueryMemoizer({
        retryIntervalBase: settings.retryIntervalBase,
        retryIntervalCap: settings.retryIntervalCap,
        initialState,
        reduce,
        source,
        onError,
    });

    setQueryArgCount(memoizer, 1);

    return memoizer;

    async function* source(
        signal: AbortSignal,
        image: string,
    ) {
        const source = await services.backend.getImageLocationsEvents({
            parameters: { image },
        });
        assert(source.status === 200);
        yield* source.entities(signal);
    }
}

//#endregion

//#region state / events

export type ImageLocationQueryEventUnion =
    adminApi.ImageLocationSnapshotEventSchema |
    adminApi.ImageLocationAddedEventSchema |
    adminApi.ImageLocationRemovedEventSchema;

export interface ImageLocationQueryState {
    entities: immutable.Set<string>;
}

const initialState: ImageLocationQueryState = {
    entities: immutable.Set(),
};

function reduce(
    state: ImageLocationQueryState,
    event: ImageLocationQueryEventUnion,
): ImageLocationQueryState {
    switch (event.type) {
        case "image-location-snapshot": {
            let { entities } = initialState;
            entities = entities.asMutable();

            for (const { id } of event.payload.locations) {
                entities.add(id);
            }

            entities.asImmutable();

            return {
                entities,
            };
        }

        case "image-location-added": {
            let { entities } = state;
            const { id } = event.payload.location;

            assert(!entities.has(id));
            entities = entities.add(id);

            return {
                ...state,
                entities,
            };
        }

        case "image-location-removed": {
            let { entities } = state;

            const { id } = event.payload.location;
            assert(entities.has(id));

            entities = entities.delete(id);

            return {
                ...state,
                entities,
            };
        }

    }

}

//#endregion

//#region selectors

export function hasImageLocation(state: ImageLocationQueryState, location: string) {
    return state.entities.has(location);
}

//#endregion
