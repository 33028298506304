import assert from "assert";
import { html } from "lit";
import { property } from "lit/decorators.js";
import { repeat } from "lit/directives/repeat.js";
import { QueryStateController } from "../../../controllers/index.js";
import * as q from "../../../queries/index.js";
import { ComponentBase } from "../../base.js";

export class LocationImageListComponent extends ComponentBase {
    @property({ type: String, reflect: true })
    location?: string

    private imageQuerySubscription = new QueryStateController(
        this,
        applicationContext.queries.image,
        applicationSettings.linger,
        () => {
            return [] as const;
        },
    )
    private locationImageQuerySubscription = new QueryStateController(
        this,
        applicationContext.queries.locationImage,
        applicationSettings.linger,
        () => {
            assert(this.location);
            return [this.location] as const;
        },
    )

    render() {
        const imageState = this.imageQuerySubscription.state;
        const locationImageState = this.locationImageQuerySubscription.state;

        if (
            !imageState ||
            !locationImageState
        ) return html`<app-loading></app-loading>`;

        const list = q.selectImageList(imageState).
            filter(({ image }) => q.hasLocationImage(locationImageState, image));

        if (list.length === 0) return html`

<p>
    No image found for this location
</p>

<app-action-bar>
<app-dialog-action type="primary" dialogName="location-images-edit" .dialogParameters=${{ location: this.location }}>
    <app-icon type="add"></app-icon>
    Add some image to this location
</app-dialog-action>
</app-action-bar>
`;

        return html`

<app-action-bar>
<app-dialog-action dialogName="location-images-edit" .dialogParameters=${{ location: this.location }}>
    <app-icon type="edit"></app-icon>
    Edit location images
</app-dialog-action>
</app-action-bar>

<app-two-columns>

${repeat(list, item => item.image, item => html`
    <div>${item.name}</div>
`)}

</app-two-columns>
`;
    }

}

