import assert from "assert";
import { html } from "lit";
import { property } from "lit/decorators.js";
import { repeat } from "lit/directives/repeat.js";
import { QueryStateController } from "../../../controllers/index.js";
import * as q from "../../../queries/index.js";
import { ComponentBase } from "../../base.js";

export class OrganizationLocationListComponent extends ComponentBase {
    @property({ type: String, reflect: true })
    organization?: string

    private locationQuerySubscription = new QueryStateController(
        this,
        applicationContext.queries.location,
        applicationSettings.linger,
        () => {
            return [] as const;
        },
    )
    private organizationLocationQuerySubscription = new QueryStateController(
        this,
        applicationContext.queries.organizationLocation,
        applicationSettings.linger,
        () => {
            assert(this.organization);
            return [this.organization] as const;
        },
    )

    render() {
        const locationState = this.locationQuerySubscription.state;
        const organizationLocationState = this.organizationLocationQuerySubscription.state;

        if (
            !locationState ||
            !organizationLocationState
        ) return html`${JSON.stringify(locationState)} ${JSON.stringify(organizationLocationState)}`;

        const list = q.selectLocationList(locationState).
            filter(({ location }) => q.hasOrganizationLocation(
                organizationLocationState,
                location,
            ));

        if (list.length === 0) return html`

<p>
    No locations found for this organization
</p>

<app-action-bar>
<app-dialog-action type="primary" dialogName="organization-locations-edit" .dialogParameters=${{ organization: this.organization }}>
    <app-icon type="add"></app-icon>
    Add some location to this organization
</app-dialog-action>
</app-action-bar>
`;

        return html`

<app-action-bar>
<app-dialog-action dialogName="organization-locations-edit" .dialogParameters=${{ organization: this.organization }}>
    <app-icon type="edit"></app-icon>
    Edit organization locations
</app-dialog-action>
</app-action-bar>

<app-two-columns>

${repeat(list, item => item.location, item => html`
    <div>${item.name}</div>
`)}

</app-two-columns>
`;
    }

}

