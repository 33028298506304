import { css, html } from "lit";
import { ComponentBase } from "../base.js";

export class DialogComponent extends ComponentBase {
    static styles = [...super.styles, css`
:host {
    display: block;
    position: fixed;
    background-color: var(--lightest-color);
    border: 1px solid var(--border-color);
    box-sizing: border-box;
    overflow: auto;
    box-shadow: 0 0 20px var(--lighter-color);
    left: var(--padding);
    right: var(--padding);
    top: var(--padding);
    bottom: var(--padding);
}

@media screen and (min-width: 900px) {
    :host {
        margin: 0 auto;
        left: 0;
        right: 0;
        top: 5%;
        bottom: auto;
        width: 800px;
        max-height: 90%;
    }
}
`];

    render() {
        return html`<slot></slot>`;
    }

    private handleKeyUp = (event: KeyboardEvent) => {
        if (event.key !== "Escape") {
            return;
        }

        history.back();
    }

    connectedCallback() {
        super.connectedCallback();

        window.addEventListener("keyup", this.handleKeyUp);
    }

    disconnectedCallback() {
        super.disconnectedCallback();

        window.removeEventListener("keyup", this.handleKeyUp);
    }
}
