import { html } from "lit";
import { ComponentBase } from "../base.js";

export class HomeRouteComponent extends ComponentBase {
    render() {
        return html`
<h1>Home</h1>
<p>
    Welcome to Gameye's admin panel. Please use the sidebar on the left to further navigate.
</p>
`;
    }
}

