import assert from "assert";
import deepEqual from "fast-deep-equal";
import { InstanceMemoizer } from "instance-memoizer";
import { AsyncDirective } from "lit/async-directive.js";
import { directive } from "lit/directive.js";
import { Query, QueryMemoizer } from "queries-kit";
import { getQueryArgCount } from "../utils/query";

export class QueryValueDirective<S, E, A extends unknown[], B extends unknown[], R>
    extends AsyncDirective {

    private abortController?: AbortController;
    private queryPromise?: Promise<Query<S, E>>;

    public state?: S;
    public args?: [...A, ...B];
    protected linger = applicationSettings.linger;

    protected source?: QueryMemoizer<S, E, A>
    protected selector?: (state: S, ...args: B) => R

    render(
        source: QueryMemoizer<S, E, A>,
        selector: (state: S, ...args: B) => R,
        ...args: [...A, ...B]
    ) {
        this.selector = selector;

        this.subscribe(source, args);

        this.source = source;
        this.args = args;

        if (this.state == null) {
            return;
        }

        const queryArgCount = getQueryArgCount(this.source);

        const selectArgs = args.slice(queryArgCount) as B;
        return this.selector(this.state, ...selectArgs);
    }

    private async run() {
        assert(this.queryPromise, "missing queryPromise");
        assert(this.abortController, "missing abortController");
        assert(this.source, "missing source");
        assert(this.selector, "missing selector");
        assert(this.args, "missing args");

        const queryArgCount = getQueryArgCount(this.source);
        const query = await this.queryPromise;

        this.state = query.getState();
        assert(this.state, "missing state");

        const selectArgs = this.args.slice(queryArgCount) as B;
        this.setValue(this.selector(this.state, ...selectArgs));
        for await (const { state } of query.fork(this.abortController.signal)) {
            this.state = state;

            const selectArgs = this.args.slice(queryArgCount) as B;
            this.setValue(this.selector(this.state, ...selectArgs));
        }
    }

    private subscribe(
        sourceNext: QueryMemoizer<S, E, A>,
        argsNext: [...A, ...B],
    ) {
        const queryArgCountNext = getQueryArgCount(sourceNext);
        const queryArgsNext = argsNext.slice(0, queryArgCountNext) as A;

        if (this.source && this.args) {
            const queryArgCount = getQueryArgCount(this.source);
            const queryArgs = this.args.slice(0, queryArgCount) as A;

            if (
                sourceNext === this.source &&
                deepEqual(queryArgs, queryArgsNext)
            ) {
                return;
            }
        }

        this.unsubscribe();

        this.queryPromise = sourceNext.acquire(...queryArgsNext);
        const abortController = this.abortController = new AbortController();

        this.args = argsNext;
        this.source = sourceNext;

        this.run().catch(error => {
            if (!abortController.signal.aborted) throw error;
        });
    }

    private unsubscribe() {
        if (this.abortController) {
            this.abortController.abort();
            this.abortController = undefined;
        }

        if (this.queryPromise && this.source) {
            this.source.release(this.queryPromise, this.linger);
            this.queryPromise = undefined;
            this.source = undefined;
        }
    }

    disconnected() {
        super.disconnected();

        this.unsubscribe();
    }

}

export const queryValue = directive(QueryValueDirective) as
    <S, E, A extends unknown[], B extends unknown[], R>(
        source: InstanceMemoizer<Promise<Query<S, E>>, A>,
        selector: (state: S, ...args: B) => R,
        ...args: [...A, ...B]
    ) => R;

