import assert from "assert";
import { css, html } from "lit";
import { property } from "lit/decorators.js";
import { ifDefined } from "lit/directives/if-defined.js";
import * as application from "../../../application/index.js";
import * as applications from "../../../application/index.js";
import { ActionComponentBase } from "./base.js";

export class RouteActionComponent extends ActionComponentBase {
    static styles = [...super.styles, css`
a {
    font: inherit;
    text-decoration: initial;
    color: inherit;
}
`]

    @property({ type: Boolean, reflect: true })
    replace = false

    @property({ type: String, reflect: true })
    routeName?: string

    @property({ type: Object, reflect: true })
    routeParameters?: Record<string, string>

    render() {
        assert(this.routeName, "expected routeName");

        const href = applications.stringifyRoute(this.routeName, this.routeParameters);

        return html`<a href=${ifDefined(href)}>${super.render()}</a>`;
    }

    connectedCallback() {
        super.connectedCallback();

        this.addEventListener("action", this.handleAction);
    }

    disconnectedCallback() {
        super.disconnectedCallback();

        this.removeEventListener("action", this.handleAction);
    }

    private handleAction = (event: Event) => {
        event.preventDefault();
        event.stopPropagation();

        assert(this.routeName, "expected routeName");

        if (this.replace) {
            application.replaceRoute(this.routeName, this.routeParameters);
        }
        else {
            application.pushRoute(this.routeName, this.routeParameters);
        }
    }

}
