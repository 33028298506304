import { css, html } from "lit";
import { ComponentBase } from "../base.js";

export class ScrollableComponent extends ComponentBase {

    static styles = [css`
:host {
    display: block;
    width: 100%;
    overflow: auto;
}
`];

    render() {
        return html`<slot></slot>`;
    }
}
