import assert from "assert";
import deepEqual from "fast-deep-equal";
import { InstanceMemoizer } from "instance-memoizer";
import { ReactiveController, ReactiveControllerHost } from "lit";
import { Query } from "queries-kit";

export class QueryStateController<S, E, A extends unknown[]>
    implements ReactiveController {

    private abortController?: AbortController;
    private queryPromise?: Promise<Query<S, E>>;

    private args?: A
    public state?: S;

    public get loading() {
        return this.state == null;
    }

    constructor(
        private readonly host: ReactiveControllerHost,
        private readonly source: InstanceMemoizer<Promise<Query<S, E>>, A>,
        private readonly linger: number,
        private readonly argsFactory: () => Readonly<A>,
    ) {
        host.addController(this);
    }

    private async run() {
        assert(this.queryPromise);
        assert(this.abortController);

        const query = await this.queryPromise;

        this.state = query.getState();
        this.host.requestUpdate();
        for await (const { state } of query.fork(this.abortController.signal)) {
            this.state = state;
            this.host.requestUpdate();
        }
    }

    private subscribe(args: A) {
        if (deepEqual(args, this.args)) {
            return;
        }

        this.unsubscribe();

        const { source } = this;

        this.queryPromise = source.acquire(...args);
        const abortController = this.abortController = new AbortController();

        this.args = args;

        this.run().catch(error => {
            if (!abortController.signal.aborted) throw error;
        });
    }

    private unsubscribe() {
        if (this.abortController) {
            this.abortController.abort();
            this.abortController = undefined;
        }

        if (this.queryPromise) {
            this.source.release(this.queryPromise, this.linger);
            this.queryPromise = undefined;
        }

        this.args = undefined;
    }

    hostDisconnected() {
        this.unsubscribe();
    }

    hostUpdate() {
        const args = this.argsFactory();
        this.subscribe(args);
    }

}
