import { Router } from "goodrouter";

export const router = createRouter();

function createRouter() {
    const router = new Router();

    router.insertRoute("home", "/");

    router.insertRoute("organization-list", "/organizations");
    router.insertRoute("organization-detail", "/organization/{organization}");
    router.insertRoute("organization-api-keys", "/organization/{organization}/api-keys");
    router.insertRoute("organization-sessions", "/organization/{organization}/sessions");
    router.insertRoute("organization-images", "/organization/{organization}/images");
    router.insertRoute("organization-locations", "/organization/{organization}/locations");

    router.insertRoute("session-logs", "/organization/{organization}/sessions/{session}/logs");
    router.insertRoute("session-detail", "/organization/{organization}/sessions/{session}");

    router.insertRoute("location-list", "/locations");
    router.insertRoute("location-detail", "/location/{location}");
    router.insertRoute("location-machines", "/location/{location}/machines");
    router.insertRoute("location-images", "/location/{location}/images");

    router.insertRoute("image-list", "/images");
    router.insertRoute("image-detail", "/image/{image}");
    router.insertRoute("image-tags", "/image/{image}/tags");
    router.insertRoute("image-locations", "/image/{image}/locations");

    router.insertRoute("region-list", "/regions");
    router.insertRoute("region-detail", "/region/{region}");

    router.insertRoute("provider-list", "/providers");

    return router;
}
