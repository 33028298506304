import { css, html } from "lit";
import { property } from "lit/decorators.js";
import { ifDefined } from "lit/directives/if-defined.js";
import { ActionComponentBase } from "./base.js";

export class LinkableActionComponent extends ActionComponentBase {
    static styles = [...super.styles, css`
a {
    font: inherit;
    text-decoration: initial;
    color: inherit;
}
`]

    @property({ type: String, reflect: true })
    href?: string

    render() {
        return html`<a href=${ifDefined(this.href)}>${super.render()}</a>`;
    }

}
