import assert from "assert";
import { html, nothing } from "lit";
import { property } from "lit/decorators.js";
import { repeat } from "lit/directives/repeat.js";
import { QueryStateController } from "../../../controllers/index.js";
import { queryValue } from "../../../directives/index.js";
import * as q from "../../../queries/index.js";
import { ComponentBase } from "../../base.js";

export class SessionListComponent extends ComponentBase {

    @property({ type: String, reflect: true })
    organization?: string

    private sessionQuerySubscription = new QueryStateController(
        this,
        applicationContext.queries.session,
        applicationSettings.linger,
        () => {
            assert(this.organization);
            return [this.organization] as const;
        },
    )

    render() {
        const { organization } = this;
        assert(organization);

        const sessionState = this.sessionQuerySubscription.state;

        if (!sessionState) {
            return html`<app-loading></app-loading>`;
        }

        const list = q.selectSessionList(sessionState);
        if (list.length === 0) return html`

<p>
    No session found!
</p>

<app-action-bar>
<app-dialog-action type="primary" dialogName="session-run" .dialogParameters=${{ organization }}>
    <app-icon type="rocket_launch"></app-icon>
    Create and start your first session.
</app-dialog-action>
</app-action-bar>
`;

        return html`
<app-action-bar>
<app-dialog-action dialogName="session-run" .dialogParameters=${{ organization }}>
    <app-icon type="rocket_launch"></app-icon>
    Run session.
</app-dialog-action>
</app-action-bar>

<app-scrollable>

<table>
<thead>
    <tr>
        <th>ID</th>
        <th>Created</th>
        <th>Image</th>
        <th>Tag</th>
        <th>Location</th>
        <th>Machine</th>
        <th>IP Address</th>
        <th>Region</th>
        <th>Active</th>
        <th></th>
    </tr>
</thead>

<tbody>
${repeat(list, item => item.session, item => html`
<tr>
    <td>${item.session}</td>
    <td>${item.created}</td>
    <td>${queryValue(applicationContext.queries.image, q.selectImageName, item.image)}</td>
    <td>${item.tag}</td>
    <td>${queryValue(applicationContext.queries.location, q.selectLocationName, item.location)}</td>
    <td>${item.machine}</td>
    <td>${item.host}</td>
    <td>${queryValue(applicationContext.queries.region, q.selectRegionName, item.region)}</td>
    <td>${item.active ? "Active" : nothing}</td>
    <td>
        <app-action-bar inline>
            <app-route-action type="primary" routeName="session-detail" .routeParameters=${{ organization, session: item.session }}>
                <app-icon type="description"></app-icon>
            </app-route-action>

            <app-dialog-action type="secondary" ?disabled=${!item.active} dialogName="session-stop" .dialogParameters=${{ organization, session: item.session }}>
                <app-icon type="block"></app-icon>
            </app-dialog-action>
        </app-action-bar>
    </td>
</tr>
`)}
</tbody>
</table>

</app-scrollable>
`;
    }
}
