import * as index from "./index.js";

declare global {
    interface HTMLElementTagNameMap {
        "app-api-key-add-dialog": index.ApiKeyAddDialog;
        "app-api-key-remove-dialog": index.ApiKeyRemoveDialog;
    }
}

customElements.define("app-api-key-add-dialog", index.ApiKeyAddDialog);
customElements.define("app-api-key-remove-dialog", index.ApiKeyRemoveDialog);

