import * as adminApi from "@gameye-managed/admin-api-spec";
import assert from "assert";
import { html, nothing, PropertyValues } from "lit";
import { BusyController, FormController } from "../../../controllers/index.js";
import { FieldValidators, isIterableEmpty } from "../../../utils/index.js";
import { ComponentBase } from "../../base.js";

type Model =
    adminApi.AddRegionApplicationJsonRequestBodySchema

const members = [
    "id",
    "names",
    "parents",
    "latlon",
] as Array<keyof Model>;

const fieldValidators = {
    ...adminApi.validateAddRegionApplicationJsonRequestBodySchema.properties,
} as FieldValidators<Model>;

function hasError(model: Partial<Model>, member: keyof Model): boolean {
    const fieldValidator = fieldValidators[member];
    return model[member] != null && !isIterableEmpty(fieldValidator(model[member]));
}

export class RegionAddComponent extends ComponentBase {
    private busy = new BusyController(this);

    private form = new FormController<Model>(
        this,
        members,
        hasError,
    )

    protected getModel() {
        return {
            id: "ENTER_REGION_ID",
            names: ["EXAMPLE_NAME_1", "EXAMPLE_NAME_2"],
            parents: ["EXAMPLE_PARENT_REGION"],
            latlon: ["0", "0"],
        };
    }

    render() {
        const { isBusy } = this.busy;
        const { model, errors } = this.form;

        if (model == null || errors == null) return nothing;

        return html`
<fieldset>

<app-text-field
    title="Id"
    data-member="id"
    .value=${model.id}
    ?error=${errors.id}
    trim
    required
></app-text-field>

<app-json-field
    title="Names"
    data-member="names"
    .value=${model.names}
    ?error=${errors.names}
    trim
    required
></app-json-field>

<app-json-field
    title="Parents"
    data-member="parents"
    .value=${model.parents}
    ?error=${errors.parents}
    trim
    required
></app-json-field>

<app-json-field
    title="Latitude-and longitude"
    data-member="latlon"
    .value=${model.latlon}
    ?error=${errors.latlon}
    trim
    required
></app-json-field>

</fieldset>

<app-action-bar>
<app-clickable-action type="primary" ?disabled=${isBusy} @action=${this.handleSubmit}>
    <app-icon type="thumb_up"></app-icon>
    Ok
</app-clickable-action>
<app-clickable-action type="secondary" ?disabled=${isBusy} @action=${this.handleCancel}>
    <app-icon type="undo"></app-icon>
    Cancel
</app-clickable-action>
</app-action-bar>
`;
    }

    private handleSubmit = this.busy.wrap(async () => {
        const { model } = this.form;
        assert(model);

        this.form.touch();
        if (!this.form.isValid()) return;

        const result = await applicationContext.services.backend.addRegion({
            parameters: {},
            entity: () => {
                return {
                    ...model as adminApi.AddRegionApplicationJsonRequestBodySchema,
                };
            },
        });

        assert(result.status === 204);

        history.back();
    })

    private handleCancel = async (event: Event) => {
        history.back();
    }

    update(changedProperties: PropertyValues) {
        super.update(changedProperties);

        if (this.form.model != null) return;

        const model = this.getModel();
        this.form.setModel(model);
    }
}
