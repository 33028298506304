import * as index from "./index.js";

declare global {
    interface HTMLElementTagNameMap {
        "app-location-images-edit-dialog": index.LocationImagesEditDialog;
    }
}

customElements.define("app-location-images-edit-dialog", index.LocationImagesEditDialog);

