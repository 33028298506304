import assert from "assert";
import { hour, minute, second } from "msecs";

export function parseInterval(value: string): number {
    const timeNotationParts = value.split(":");
    assert(timeNotationParts.length === 2 || timeNotationParts.length === 3);

    const hours = Number(timeNotationParts[0]) * hour;
    const minutes = Number(timeNotationParts[1]) * minute;

    assert(hours >= 0);
    assert(minutes >= 0);

    let seconds = 0;
    if (timeNotationParts.length === 3) {
        seconds = parseFloat(timeNotationParts[2]) * second;
        assert(seconds >= 0);
    }

    return hours + minutes + seconds;
}

export function formatInterval(value: number): string {
    const minutes = Math.floor(value / minute % 60);
    const hours = Math.floor(value / hour);

    return "" +
        String(hours).padStart(2, "0") +
        ":" +
        String(minutes).padStart(2, "0");
}
