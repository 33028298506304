import assert from "assert";
import { html } from "lit";
import { property } from "lit/decorators.js";
import { repeat } from "lit/directives/repeat.js";
import { QueryStateController } from "../../../controllers/index.js";
import * as q from "../../../queries/index.js";
import { ComponentBase } from "../../base.js";

export class TagListComponent extends ComponentBase {

    @property({ type: String, reflect: true })
    image?: string

    private queryState = new QueryStateController(
        this,
        applicationContext.queries.tag,
        applicationSettings.linger,
        () => {
            assert(this.image);
            return [this.image] as const;
        },
    )

    render() {
        const { image } = this;
        assert(image);

        const tagState = this.queryState.state;

        if (!tagState) return html`<app-loading></app-loading>`;

        const list = q.selectTagList(tagState);
        if (list.length === 0) return html`

<p>
    No tag found!
</p>

<app-action-bar>
<app-dialog-action type="primary" dialogName="tag-add" .dialogParameters=${{ image }}>
    <app-icon type="add"></app-icon>
    Create your first tag.
</app-dialog-action>
</app-action-bar>
`;

        return html`

<app-action-bar>
<app-dialog-action dialogName="tag-add" .dialogParameters=${{ image }}>
    <app-icon type="add"></app-icon>
    Add tag
</app-dialog-action>
</app-action-bar>

<app-scrollable>

<table>
<thead>
    <tr>
        <th>ID</th>
        <th>Priority</th>
        <th></th>
    </tr>
</thead>

<tbody>
${repeat(list, item => item.tag, item => html`
<tr>
    <td>${item.tag}</td>
    <td>${item.priority}</td>
    <td>
        <div>
            <app-dialog-action dialogName="tag-remove" .dialogParameters=${{ image, tag: item.tag }} >
                <app-icon type="delete"></app-icon>
            </app-dialog-action>
        </div>
    </td>
</tr>
`)}
</tbody>
</table>

</app-scrollable>
`;
    }
}
