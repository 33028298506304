import * as q from "../queries/index.js";
import { Services } from "./service.js";
import { Settings } from "./settings.js";

export interface Queries {
    organization: q.OrganizationQueryMemoizer
    location: q.LocationQueryMemoizer
    image: q.ImageQueryMemoizer
    machine: q.MachineQueryMemoizer
    allMachines: q.AllMachinesQueryMemoizer,
    tag: q.TagQueryMemoizer
    apiKey: q.ApiKeyQueryMemoizer
    session: q.SessionQueryMemoizer
    sessionLogs: q.SessionLogsQueryMemoizer
    organizationImage: q.OrganizationImageQueryMemoizer
    organizationLocation: q.OrganizationLocationQueryMemoizer
    imageOrganization: q.ImageOrganizationQueryMemoizer
    imageLocation: q.ImageLocationQueryMemoizer
    locationImage: q.LocationImageQueryMemoizer
    region: q.RegionQueryMemoizer
    provider: q.ProviderQueryMemoizer
}

export function createQueries(
    settings: Settings,
    services: Services,
    onError: (error: unknown) => void,
): Queries {
    const organization = q.createOrganizationQueryMemoizer(services, settings, onError);
    const location = q.createLocationQueryMemoizer(services, settings, onError);
    const image = q.createImageQueryMemoizer(services, settings, onError);
    const machine = q.createMachineQueryMemoizer(services, settings, onError);
    const allMachines = q.createAllMachinesQueryMemoizer(services, settings, onError);
    const tag = q.createTagQueryFactory(services, settings, onError);
    const apiKey = q.createApiKeyQueryMemoizer(services, settings, onError);
    const session = q.createSessionQueryFactory(services, settings, onError);
    const sessionLogs = q.createSessionLogsQueryFactory(services, settings, onError);
    const organizationImage = q.createOrganizationImageQueryMemoizer(services, settings, onError);

    const organizationLocation = q.createOrganizationLocationQueryMemoizer(
        services,
        settings,
        onError,
    );

    const imageOrganization = q.createImageOrganizationQueryMemoizer(services, settings, onError);
    const imageLocation = q.createImageLocationQueryMemoizer(services, settings, onError);
    const locationImage = q.createLocationImageQueryMemoizer(services, settings, onError);
    const region = q.createRegionQueryFactory(services, settings, onError);
    const provider = q.createProviderQueryMemoizer(services, settings, onError);

    return {
        organization,
        location,
        image,
        machine,
        allMachines,
        tag,
        apiKey,
        session,
        sessionLogs,
        organizationImage,
        organizationLocation,
        imageLocation,
        imageOrganization,
        locationImage,
        region,
        provider,
    };
}
