import { html } from "lit";
import { repeat } from "lit/directives/repeat.js";
import { QueryStateController } from "../../../controllers/index.js";
import { ComponentBase } from "../../base.js";
import { queryValue } from "../../../directives/index.js";
import {
    selectLocationList,
    selectAllMachines,
    selectRegionName,
} from "../../../queries/index.js";

interface ListButtonOptions {
    name: string,
    type: string,
    icon: string,
}

function locationNotFound() {
    return html`
        <p>
            No location found!
        </p>

        <app-action-bar>
            <app-dialog-action type="primary" dialogName="location-add">
                <app-icon type="add"></app-icon>
                Create your first location.
            </app-dialog-action>
        </app-action-bar>
    `;
}

function listButton(location: string, options: ListButtonOptions) {
    const routeName = `location-${options.name}`;
    const params = {
        location,
    };

    if (options.type === "primary") { 
        return html`
            <app-route-action
                type=${options.type}
                routeName=${routeName}
                .routeParameters=${params}
            >
                <app-icon type=${options.icon}></app-icon>
            </app-route-action>
        `;
    }

    return html`
        <app-dialog-action
            type=${options.type}
            dialogName=${routeName}
            .dialogParameters=${params}
        >
            <app-icon type=${options.icon}></app-icon>
        </app-dialog-action>
    `;
}

export class LocationListComponent extends ComponentBase {
    private locationState = new QueryStateController(
        this,
        applicationContext.queries.location,
        applicationSettings.linger,
        () => [] as const,
    )

    private allMachinesState = new QueryStateController(
        this,
        applicationContext.queries.allMachines,
        applicationSettings.linger,
        () => [] as const,
    )

    render() {
        const locationState = this.locationState.state;
        const allMachinesState = this.allMachinesState.state;

        if (!locationState || !allMachinesState) {
            return html`<app-loading></app-loading>`;
        }

        const list = selectLocationList(locationState);
        const machines = selectAllMachines(allMachinesState);

        if (!list.length) return locationNotFound();

        return html`
            <app-action-bar>
                <app-dialog-action dialogName="location-add">
                    <app-icon type="add"></app-icon>
                    Add location
                </app-dialog-action>
            </app-action-bar>

            <app-scrollable>
                <table>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Name</th>
                            <th>Region</th>
                            <th>Type</th>
                            <th>Provider</th>
                            <th>Machines</th>
                            <th></th>
                        </tr>
                    </thead>

                    <tbody>
                    ${repeat(list, item => item.location, item => html`
                        <tr>
                            <td>${item.location}</td>
                            <td>${item.name}</td>
                            <td>
                                ${queryValue(
                                    applicationContext.queries.region,
                                    selectRegionName,
                                    item.geoLocation,
                                )}
                            </td>
                            <td>${item.type}</td>
                            <td>${item.provider}</td>
                            <td>
                                ${machines.get(item.location)?.count() ?? 0}
                            </td>
                            <td>
                                <app-action-bar inline>
                                    ${listButton(item.location, {
                                        name: "detail",
                                        type: "primary",
                                        icon: "description",
                                    })}
                                    ${listButton(item.location, {
                                        name: "edit",
                                        type: "secondary",
                                        icon: "edit",
                                    })}
                                    ${listButton(item.location, {
                                        name: "remove",
                                        type: "secondary",
                                        icon: "delete",
                                    })}
                                </app-action-bar>
                            </td>
                        </tr>
                    `)}
                    </tbody>
                </table>
            </app-scrollable>
        `;
    }
}
