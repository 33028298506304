import assert from "assert";
import { html } from "lit";
import { property } from "lit/decorators.js";
import { QueryStateController } from "../../../controllers/index.js";
import * as q from "../../../queries/index.js";
import { formatInterval } from "../../../utils/interval.js";
import { ComponentBase } from "../../base.js";

export class LocationDetailComponent extends ComponentBase {

    @property({ type: String, reflect: true })
    location?: string

    private queryState = new QueryStateController(
        this,
        applicationContext.queries.location,
        applicationSettings.linger,
        () => {
            return [] as const;
        },
    )

    render() {
        const locationState = this.queryState.state;

        if (!locationState) return html`<app-loading></app-loading>`;

        assert(this.location);

        const item = q.selectLocationItem(locationState, this.location);
        if (item == null) return html`<p>Not found!</p>`;

        return html`

<app-action-bar>
    <app-dialog-action dialogName="location-edit" .dialogParameters=${{ location: item.location }}>
        <app-icon type="edit"></app-icon>
        Edit this location
    </app-dialog-action>
</app-action-bar>

<dl>
    <dt>ID</dt>
    <dd>${item.location}</dd>

    <dt>Name</dt>
    <dd>${item.name}</dd>

    <dt>Region</dt>
    <dd>${item.geoLocation}</dd>

    <dt>Type</dt>
    <dd>${item.type}</dd>
    <dt>Provider</dt>
    <dd>${item.provider}</dd>
    <dt>Machine linger interval</dt>
    <dd>${formatInterval(item.machineLingerInterval ?? 0)}</dd>

    <dt>Machine ready interval</dt>
    <dd>${formatInterval(item.machineReadyInterval ?? 0)}</dd>

    <dt>Config</dt>
    <dd><pre>${JSON.stringify(item.config, undefined, 2)}</pre></dd>

    <dt>Limits</dt>
    <dd><pre>${JSON.stringify(item.limits, undefined, 2)}</pre></dd>

    <dt>Advantages</dt>
    <dd><pre>${JSON.stringify(item.advantages, undefined, 2)}</pre></dd>
</dl>
`;
    }
}
