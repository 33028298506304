import { AllMachinesAddedEventSchema as AddedSchema, AllMachinesInitializedEventSchema as InitSchema, AllMachinesRemovedEventSchema as RemovedSchema, AllMachinesUpdatedEventSchema as UpdatedSchema } from "@gameye-managed/admin-api-spec";
import assert from "assert";
import immutable, { Map } from "immutable";
import { createQueryMemoizer, Query, QueryMemoizer } from "queries-kit";
import { Services, Settings } from "../application/index.js";
import { MachineQueryEntity } from "./machine.js";

export type AllMachinesQueryEventUnion =
    InitSchema |
    AddedSchema |
    RemovedSchema |
    UpdatedSchema;

export type AllMachinesMap = Map<string, Map<string, MachineQueryEntity>>;

export interface AllMachinesQueryState {
    entities: AllMachinesMap;
}

const initialState: AllMachinesQueryState = {
    entities: Map(),
};

export type AllMachinesQuery = Query<
    AllMachinesQueryState,
    AllMachinesQueryEventUnion
>;

export type AllMachinesQueryMemoizer = QueryMemoizer<
    AllMachinesQueryState,
    AllMachinesQueryEventUnion,
    []
>;

export function createAllMachinesQueryMemoizer(
    { backend }: Services,
    { retryIntervalBase, retryIntervalCap }: Settings,
    onError: (error: unknown) => void,
): AllMachinesQueryMemoizer {
    async function* source(signal: AbortSignal) {
        const { status, entities } = await backend.allMachinesQuery({
            parameters: {},
        });

        assert(status === 200);

        yield* entities(signal);
    }

    return createQueryMemoizer({
        retryIntervalBase,
        retryIntervalCap,
        initialState,
        reduce,
        source,
        onError,
    });
}

export function selectAllMachines(state: AllMachinesQueryState): AllMachinesMap {
    return state.entities;
}

function reduce(
    state: AllMachinesQueryState,
    event: AllMachinesQueryEventUnion,
): AllMachinesQueryState {
    switch (event.type) {
        case "all-machines-initialized": {
            let { entities } = state;

            entities = entities.withMutations(mutator => {
                for (const {
                    location,
                    machines,
                } of event.payload.machines) {
                    let list = mutator.get(location);
                    if (!list) {
                        list = immutable.Map();
                    }

                    for (const {
                        name,
                        providerId,
                        ipV4Address,
                        reachable,
                        available,
                    } of machines) {
                        list = list.set(name, {
                            providerId,
                            ipV4Address,
                            reachable,
                            available,
                        });
                    }

                    mutator.set(location, list);
                }
            });

            return {
                entities,
            };
        }

        case "all-machines-added": {
            const {
                location,
                machine,
            } = event.payload;

            let { entities } = state;

            let machines = entities.get(location);
            if (!machines) {
                machines = immutable.Map();
            }

            machines = machines.set(machine.name, {
                ipV4Address: machine.ipV4Address,
                providerId: machine.providerId,
                reachable: machine.reachable,
                available: machine.available,
            });

            entities = entities.set(location, machines);

            return {
                entities,
            };
        }

        case "all-machines-removed": {
            const {
                location,
                machine,
            } = event.payload;

            let { entities } = state;

            let machines = entities.get(location);
            assert(machines, `location ${location} does not exist`);

            machines = machines.delete(machine.id);
            if (machines.isEmpty()) {
                entities = entities.delete(location);
            } else {
                entities = entities.set(location, machines);
            }

            return {
                entities,
            };
        }

        case "all-machines-updated": {
            const {
                location,
                machine,
            } = event.payload;

            let { entities } = state;

            let machines = entities.get(location);
            assert(machines, `location ${location} does not exist`);

            const machineEntity = machines.get(machine.name);
            assert(machineEntity, `machine ${machine.name} does not exist in location ${location}`);

            machines = machines.set(machine.name, {
                ...machineEntity,

                ipV4Address: machine.ipV4Address,
                providerId: machine.providerId,
                reachable: machine.reachable,
                available: machine.available,
            });

            entities = entities.set(location, machines);

            return {
                entities,
            };
        }
    }
}
