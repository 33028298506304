import { html } from "lit";
import { property } from "lit/decorators.js";
import { ComponentBase } from "../../base.js";

export class ApiKeyRemoveDialog extends ComponentBase {
    @property({ type: String, reflect: true })
    organization!: string

    @property({ type: String, reflect: true })
    token!: string

    render() {
        return html`
<h1>Remove api-key</h1>
<app-api-key-remove organization=${this.organization} token=${this.token}></app-api-key-remove>
`;
    }

}
