import * as adminApi from "@gameye-managed/admin-api-spec";
import assert from "assert";
import immutable from "immutable";
import { createQueryMemoizer, Query, QueryMemoizer } from "queries-kit";
import * as application from "../application/index.js";
import { setQueryArgCount } from "../utils/index.js";

//#region query

export type TagQueryMemoizer = QueryMemoizer<
    TagQueryState, TagQueryEventUnion, [string]
>

export type TagQuery = Query<TagQueryState, TagQueryEventUnion>

export function createTagQueryFactory(
    services: application.Services,
    settings: application.Settings,
    onError: (error: unknown) => void,
): TagQueryMemoizer {
    const memoizer = createQueryMemoizer({
        retryIntervalBase: settings.retryIntervalBase,
        retryIntervalCap: settings.retryIntervalCap,
        initialState,
        reduce,
        source,
        onError,
    });

    setQueryArgCount(memoizer, 1);

    return memoizer;

    async function* source(
        signal: AbortSignal,
        image: string,
    ) {
        const source = await services.backend.tagQuery({
            parameters: { image },
        });
        assert(source.status === 200);
        yield* source.entities(signal);
    }
}

//#endregion

//#region state / events

export type TagQueryEventUnion =
    adminApi.TagInitializedSchema |
    adminApi.TagAddedSchema |
    adminApi.TagRemovedSchema;

export interface TagQueryEntity {
    priority: number,
}

export interface TagQueryState {
    entities: immutable.Map<string, TagQueryEntity>;
}

const initialState: TagQueryState = {
    entities: immutable.Map(),
};

function reduce(
    state: TagQueryState,
    event: TagQueryEventUnion,
): TagQueryState {
    switch (event.type) {
        case "tag-initialized": {
            let { entities } = initialState;
            entities = entities.asMutable();

            for (const tag of event.payload.tags) {
                const { id, priority } = tag;

                const entity = {
                    priority,
                };
                entities.set(id, entity);
            }

            entities.asImmutable();

            return {
                entities,
            };
        }

        case "tag-added": {
            let { entities } = state;

            const { id, priority } = event.payload.tag;
            const entity = {
                priority,
            };

            assert(!entities.get(id));
            entities = entities.set(id, entity);

            return {
                ...state,
                entities,
            };
        }

        case "tag-removed": {
            let { entities } = state;

            const { id } = event.payload.tag;

            assert(entities.has(id));

            entities = entities.delete(id);

            return {
                ...state,
                entities,
            };
        }

    }

}

//#endregion

//#region selectors

export function selectTagList(state: TagQueryState) {
    return [
        ...state.entities.
            map((value, key) => mapEntity(key, value)).
            sortBy(value => value.priority).
            reverse().
            values(),
    ];
}

export function selectTagItem(
    state: TagQueryState,
    tag: string,
) {
    const entity = state.entities.get(tag);
    if (!entity) return;

    return mapEntity(tag, entity);
}

export function selectTagOptions(state: TagQueryState) {
    return selectTagList(state).
        map(({ tag }) => [tag, tag] as const);
}

function mapEntity(tag: string, entity: TagQueryEntity) {
    return {
        tag,
        ...entity,
    };
}

//#endregion
