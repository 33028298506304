import * as index from "./index.js";

declare global {
    interface HTMLElementTagNameMap {
        "app-organization-image-list": index.OrganizationImageListComponent;
        "app-organization-images-edit": index.OrganizationImagesEditComponent;
    }
}

customElements.define("app-organization-image-list", index.OrganizationImageListComponent);
customElements.define("app-organization-images-edit", index.OrganizationImagesEditComponent);

