import { html } from "lit";
import { ComponentBase } from "../../base.js";

export class ProviderListRouteComponent extends ComponentBase {

    render() {
        return html`
<h1>Providers</h1>
<app-provider-list></app-provider-list>
`;
    }
}

