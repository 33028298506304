import * as index from "./index.js";

declare global {
    interface HTMLElementTagNameMap {
        "app-organization-images-edit-dialog": index.OrganizationImagesEditDialog;
    }
}

customElements.define("app-organization-images-edit-dialog", index.OrganizationImagesEditDialog);

