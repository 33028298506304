import { property } from "@lit/reactive-element/decorators/property.js";
import { css } from "lit";
import { DocumentStyleController } from "../../controllers/document-style.js";
import { ComponentBase } from "../base.js";

const documentStyle = css`
@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/materialicons/v118/flUhRq6tzZclQEJ-Vdg-IuiaDsNZ.ttf) format('truetype');
}
`;

export class IconComponent extends ComponentBase {
    documentStyle = new DocumentStyleController(this, documentStyle);

    static styles = [css`
:host {
    all: initial;
    font-size: inherit;
    color: inherit;
    text-transform: none;
    font-family: 'Material Icons';
    cursor: inherit;
}
`];

    @property({ type: String, reflect: true })
    type?: string

    render() {
        return this.type;
    }
}
