import * as index from "./index.js";

declare global {
    interface HTMLElementTagNameMap {
        "app-session-run-dialog": index.SessionRunDialog;
        "app-session-stop-dialog": index.SessionStopDialog;
    }
}

customElements.define("app-session-run-dialog", index.SessionRunDialog);
customElements.define("app-session-stop-dialog", index.SessionStopDialog);

