import { html } from "lit";
import { property } from "lit/decorators.js";
import { ComponentBase } from "../../base.js";

export class MachineRemoveDialog extends ComponentBase {
    @property({ type: String, reflect: true })
    location!: string

    @property({ type: String, reflect: true })
    machine!: string

    render() {
        return html`
<h1>Remove machine</h1>
<app-machine-remove location=${this.location} machine=${this.machine}></app-machine-remove>
`;
    }

}
