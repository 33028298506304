import * as index from "./index.js";

declare global {
    interface HTMLElementTagNameMap {
        "app-image-list-route": index.ImageListRouteComponent;
        "app-image-detail-route": index.ImageDetailRouteComponent;
        "app-image-tags-route": index.ImageTagsRouteComponent;
        "app-image-locations-route": index.ImageLocationsRouteComponent;
    }
}

customElements.define("app-image-list-route", index.ImageListRouteComponent);
customElements.define("app-image-detail-route", index.ImageDetailRouteComponent);
customElements.define("app-image-tags-route", index.ImageTagsRouteComponent);
customElements.define("app-image-locations-route", index.ImageLocationsRouteComponent);

