import { css, LitElement } from "lit";
import { DocumentStyleController } from "../controllers/document-style";

const documentStyle = css`

@font-face {
  font-family: 'Kanit';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/kanit/v7/nKKZ-Go6G5tXcoaS.ttf) format('truetype');
}

`;

export class ComponentBase extends LitElement {
    private baseDocumentStyle = new DocumentStyleController(this, documentStyle);

    static styles = [css`

:host {
    font-family: sans-serif;
    font-size: var(--font-size);
}

h1,
h2,
h3,
p {
    all: initial;
    font: inherit;
    display: block;
    padding: var(--padding);
}
h1,
h2,
h3 {
    font-family: Kanit, sans-serif;
    color: var(--darker-color);
}
p {
    color: var(--darkest-color);
}
h1 {
    font-size: 2em;
}
h2 {
    font-size: 1.5em;
}
h3 {
    font-size: 1.2em;
}

p {
    font-size: 1em;
}

pre {
    font-family: monospace;
    font-size: var(--font-size);
    padding: var(--padding);
}

dl {
  all: initial;
  font: inherit;
  display: block;
  padding: var(--padding);
}

fieldset {
    all: initial;
    font: inherit;
    display: flex;
    flex-direction: column;
    gap: var(--padding);
    padding: var(--padding);
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

table td, table th  {
    color: var(--darker-color);
    padding: var(--padding);
}

table th  {
    font: inherit;
    font-family: Kanit, sans-serif;
    white-space: nowrap;
}

table td {
    white-space: nowrap;
    text-overflow: ellipsis;
}

table > tbody > tr {
    border-top: 1px solid var(--border-color);
}

`];

}
