import { html } from "lit";
import { property } from "lit/decorators.js";
import { ComponentBase } from "../../base.js";

export class LocationRemoveDialog extends ComponentBase {
    @property({ type: String, reflect: true })
    location!: string

    render() {
        return html`
<h1>Remove location</h1>
<app-location-remove location=${this.location}></app-location-remove>
`;
    }

}
