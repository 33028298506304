import { html } from "lit";
import { property } from "lit/decorators.js";
import { ComponentBase } from "../../base.js";

export class RegionDetailRouteComponent extends ComponentBase {

    @property({ type: String, reflect: true })
    region!: string;

    render() {
        return html`
<h1>Region ${this.region} details</h1>

<app-region-header region=${this.region}></app-region-header>
<app-region-detail region=${this.region}></app-region-detail>
`;
    }
}

