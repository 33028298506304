import * as index from "./index.js";

declare global {
    interface HTMLElementTagNameMap {
        "app-session-detail-route": index.SessionDetailRouteComponent;
        "app-session-logs-route": index.SessionLogsRouteComponent;
    }
}

customElements.define("app-session-detail-route", index.SessionDetailRouteComponent);
customElements.define("app-session-logs-route", index.SessionLogsRouteComponent);

