import * as index from "./index.js";

declare global {
    interface HTMLElementTagNameMap {
        "app-image-create-dialog": index.ImageCreateDialog;
        "app-image-remove-dialog": index.ImageRemoveDialog;
        "app-image-edit-dialog": index.ImageEditDialog;
    }
}

customElements.define("app-image-create-dialog", index.ImageCreateDialog);
customElements.define("app-image-remove-dialog", index.ImageRemoveDialog);
customElements.define("app-image-edit-dialog", index.ImageEditDialog);

