import assert from "assert";
import { html } from "lit";
import { property } from "lit/decorators.js";
import { repeat } from "lit/directives/repeat.js";
import { QueryStateController } from "../../../controllers/index.js";
import * as q from "../../../queries/index.js";
import { ComponentBase } from "../../base.js";

export class MachineListComponent extends ComponentBase {

    @property({ type: String, reflect: true })
    location?: string

    private queryState = new QueryStateController(
        this,
        applicationContext.queries.machine,
        applicationSettings.linger,
        () => {
            assert(this.location);
            return [this.location] as const;
        },
    )

    render() {
        const { location } = this;
        assert(location);

        const machineState = this.queryState.state;

        if (!machineState) return html`<app-loading></app-loading>`;

        const list = q.selectMachineList(machineState);
        if (list.length === 0) return html`

<p>
    No machine found!
</p>

<app-action-bar>
<app-dialog-action type="primary" dialogName="machine-add" .dialogParameters=${{ location }}>
    <app-icon type="add"></app-icon>
    Create your first machine.
</app-dialog-action>
</app-action-bar>

<app-action-bar>
<app-dialog-action type="primary" dialogName="machine-provision" .dialogParameters=${{ location }}>
    <app-icon type="add"></app-icon>
    Provision your first machine.
</app-dialog-action>
</app-action-bar>
`;

        return html`
<app-action-bar>
<app-dialog-action dialogName="machine-add" .dialogParameters=${{ location }}>
    <app-icon type="add"></app-icon>
    Add machine
</app-dialog-action>

<app-dialog-action dialogName="machine-provision" .dialogParameters=${{ location }}>
    <app-icon type="add"></app-icon>
    Provision machine
</app-dialog-action>

</app-action-bar>

<app-scrollable>

<table>
<thead>
    <tr>
        <th>ID</th>
        <th>ID in Provider</th>
        <th>IPV4</th>
        <th>Reachable</th>
        <th>Available</th>
        <th></th>
    </tr>
</thead>

<tbody>
${repeat(list, item => item.machine, item => html`
<tr>
    <td>${item.machine}</td>
    <td>${item.providerId}</td>
    <td>${item.ipV4Address}</td>
    <td>${item.reachable ? "Yes" : "No"}</td>
    <td>${item.available ? "Yes" : "No"}</td>
    <td>
        <app-action-bar inline>
        <app-dialog-action type="secondary" dialogName="machine-edit" .dialogParameters=${{ location, machine: item.machine }} >
            <app-icon type="edit"></app-icon>
        </app-dialog-action>
        <app-dialog-action type="secondary" dialogName="machine-remove" .dialogParameters=${{ location, machine: item.machine }} >
            <app-icon type="delete"></app-icon>
        </app-dialog-action>
        </app-action-bar>
    </td>
</tr>
`)}
</tbody>
</table>

</app-scrollable>
`;
    }
}
