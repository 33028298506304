import { html } from "lit";
import { property } from "lit/decorators.js";
import { ComponentBase } from "../../base.js";

export class TagRemoveDialog extends ComponentBase {
    @property({ type: String, reflect: true })
    image!: string

    @property({ type: String, reflect: true })
    tag!: string

    render() {
        return html`
<h1>Remove tag</h1>
<app-tag-remove image=${this.image} tag=${this.tag}></app-tag-remove>
`;
    }

}
