import * as index from "./index.js";

declare global {
    interface HTMLElementTagNameMap {
        "app-image-locations-edit-dialog": index.ImageLocationsEditDialog;
    }
}

customElements.define("app-image-locations-edit-dialog", index.ImageLocationsEditDialog);

