import assert from "assert";
import deepEqual from "fast-deep-equal";
import { ReactiveController, ReactiveControllerHost } from "lit";
import { Query, QueryMemoizer } from "queries-kit";
import { getQueryArgCount } from "../utils/query";

export class QueryValueController<S, E, A extends unknown[], B extends unknown[], R>
    implements ReactiveController {

    private abortController?: AbortController;
    private queryPromise?: Promise<Query<S, E>>;

    private args?: Readonly<[...A, ...B]>
    private state?: S
    public value?: R

    public get loading() {
        return this.state == null;
    }

    constructor(
        private readonly host: ReactiveControllerHost,
        private readonly source: QueryMemoizer<S, E, A>,
        private readonly linger: number,
        private selector: (state: S, ...args: B) => R,
        private readonly argsFactory: () => Readonly<[...A, ...B]>,
    ) {
        host.addController(this);
    }

    private async run() {
        assert(this.queryPromise);
        assert(this.abortController);
        assert(this.args);

        const queryArgCount = getQueryArgCount(this.source);

        const query = await this.queryPromise;
        this.state = query.getState();
        this.value = this.selector(
            this.state,
            ...this.args.slice(queryArgCount) as B,
        );
        this.host.requestUpdate();
        for await (const { state } of query.fork(this.abortController.signal)) {
            this.state = state;
            this.value = this.selector(
                state,
                ...this.args.slice(queryArgCount) as B,
            );
            this.host.requestUpdate();
        }
    }

    private subscribe(args: Readonly<[...A, ...B]>) {
        if (deepEqual(args, this.args)) {
            return;
        }

        this.unsubscribe();

        const { source } = this;

        const queryArgCount = getQueryArgCount(source);

        this.queryPromise = source.acquire(...args.slice(0, queryArgCount) as A);
        const abortController = this.abortController = new AbortController();

        this.args = args;

        this.run().catch(error => {
            if (!abortController.signal.aborted) throw error;
        });
    }

    private unsubscribe() {
        if (this.abortController) {
            this.abortController.abort();
            this.abortController = undefined;
        }

        if (this.queryPromise) {
            this.source.release(this.queryPromise, this.linger);
            this.queryPromise = undefined;
        }

        this.args = undefined;
    }

    hostDisconnected() {
        this.unsubscribe();
    }

    hostUpdate() {
        const args = this.argsFactory();
        this.subscribe(args);
    }

}
