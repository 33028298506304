import { html } from "lit";
import { property } from "lit/decorators.js";
import { ComponentBase } from "../../base.js";

export class ImageDetailRouteComponent extends ComponentBase {

    @property({ type: String, reflect: true })
    image!: string;

    render() {
        return html`
<h1>Image ${this.image} details</h1>

<app-image-header image=${this.image}></app-image-header>
<app-image-detail image=${this.image}></app-image-detail>
`;
    }
}

