import assert from "assert";
import { html } from "lit";
import { property } from "lit/decorators.js";
import { repeat } from "lit/directives/repeat.js";
import { QueryStateController } from "../../../controllers/index.js";
import * as q from "../../../queries/index.js";
import { ComponentBase } from "../../base.js";

export class OrganizationImageListComponent extends ComponentBase {
    @property({ type: String, reflect: true })
    organization?: string

    private imageQuerySubscription = new QueryStateController(
        this,
        applicationContext.queries.image,
        applicationSettings.linger,
        () => {
            return [] as const;
        },
    )
    private organizationImageQuerySubscription = new QueryStateController(
        this,
        applicationContext.queries.organizationImage,
        applicationSettings.linger,
        () => {
            assert(this.organization);
            return [this.organization] as const;
        },
    )

    render() {
        const imageState = this.imageQuerySubscription.state;
        const organizationImageState = this.organizationImageQuerySubscription.state;

        if (
            !imageState ||
            !organizationImageState
        ) return html`<app-loading></app-loading>`;

        const list = q.selectImageList(imageState).
            filter(({ image }) => q.hasOrganizationImage(organizationImageState, image));

        if (list.length === 0) return html`

<p>
    No image found for this organization
</p>

<app-action-bar>
<app-dialog-action type="primary" dialogName="organization-images-edit" .dialogParameters=${{ organization: this.organization }}>
    <app-icon type="add"></app-icon>
    Add some image to this organization
</app-dialog-action>
</app-action-bar>
`;

        return html`

<app-action-bar>
<app-dialog-action dialogName="organization-images-edit" .dialogParameters=${{ organization: this.organization }}>
    <app-icon type="edit"></app-icon>
    Edit organization images
</app-dialog-action>
</app-action-bar>

<app-two-columns>

${repeat(list, item => item.image, item => html`
    <div>${item.name}</div>
`)}

</app-two-columns>
`;
    }

}

