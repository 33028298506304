import assert from "assert";
import { html } from "lit";
import { property } from "lit/decorators.js";
import { repeat } from "lit/directives/repeat.js";
import { BusyController, QueryStateController } from "../../../controllers/index.js";
import * as q from "../../../queries/index.js";
import { ComponentBase } from "../../base.js";
import { ActionComponentBase } from "../../molecules/index.js";

export class OrganizationLocationEditComponent extends ComponentBase {
    @property({ type: String, reflect: true })
    organization!: string

    private busy = new BusyController(this);

    private locationQuerySubscription = new QueryStateController(
        this,
        applicationContext.queries.location,
        applicationSettings.linger,
        () => {
            return [] as const;
        },
    )
    private organizationLocationQuerySubscription = new QueryStateController(
        this,
        applicationContext.queries.organizationLocation,
        applicationSettings.linger,
        () => [this.organization] as const,
    )

    render() {
        const locationState = this.locationQuerySubscription.state;
        const organizationLocationState = this.organizationLocationQuerySubscription.state;
        const { isBusy } = this.busy;

        if (
            !locationState ||
            !organizationLocationState
        ) return html`<app-loading></app-loading>`;

        const list = q.selectLocationList(locationState);

        return html`
<app-two-columns>

${repeat(list, item => item.location, item => html`
<label>
    <input
        type="checkbox"
        value=${item.location}
        ?checked=${q.hasOrganizationLocation(organizationLocationState, item.location)}
        ?disabled=${isBusy}
        data-location=${item.location}
    />
    ${item.name}
</label>
`)}

</app-two-columns>

<app-action-bar>
    <app-clickable-action type="secondary" data-action="close" ?disabled=${isBusy}>
        <app-icon type="close"></app-icon>
        Close
    </app-clickable-action>
</app-action-bar>
`;

    }

    connectedCallback() {
        super.connectedCallback();

        this.renderRoot.addEventListener("change", this.handleChange);
        this.renderRoot.addEventListener("action", this.handleAction);
    }

    disconnectedCallback() {
        super.disconnectedCallback();

        this.renderRoot.removeEventListener("change", this.handleChange);
        this.renderRoot.removeEventListener("action", this.handleAction);
    }

    handleChange = (event: Event) => {
        if (!(event.target instanceof HTMLInputElement)) {
            return;
        }

        const target = event.target;
        assert(target.dataset.location);

        if (target.checked) {
            this.busy.with(
                applicationContext.services.backend.addOrganizationLocation({
                    parameters: {
                        organization: this.organization,
                        location: target.dataset.location,
                    },
                    entity() {
                        return {};
                    },
                }),
            );
        }
        else {
            this.busy.with(
                applicationContext.services.backend.removeOrganizationLocation({
                    parameters: {
                        organization: this.organization,
                        location: target.dataset.location,
                    },
                }),
            );
        }

    }

    private handleAction = async (event: Event) => {
        if (!(event.target instanceof ActionComponentBase)) return;

        const target = event.target;
        switch (target.dataset.action) {
            case "close": {
                history.back();
                break;
            }
        }

    }

}

