import assert from "assert";
import { html } from "lit";
import { property } from "lit/decorators.js";
import { repeat } from "lit/directives/repeat.js";
import { QueryStateController } from "../../../controllers/index.js";
import * as q from "../../../queries/index.js";
import { ComponentBase } from "../../base.js";

export class ImageLocationListComponent extends ComponentBase {
    @property({ type: String, reflect: true })
    image?: string

    private locationQuerySubscription = new QueryStateController(
        this,
        applicationContext.queries.location,
        applicationSettings.linger,
        () => {
            return [] as const;
        },
    )
    private imageLocationQuerySubscription = new QueryStateController(
        this,
        applicationContext.queries.imageLocation,
        applicationSettings.linger,
        () => {
            assert(this.image);
            return [this.image] as const;
        },
    )

    render() {
        const locationState = this.locationQuerySubscription.state;
        const imageLocationState = this.imageLocationQuerySubscription.state;

        if (
            !locationState ||
            !imageLocationState
        ) return html`<app-loading></app-loading>`;

        const list = q.selectLocationList(locationState).
            filter(({ location }) => q.hasImageLocation(imageLocationState, location));

        if (list.length === 0) return html`

<p>
    No locations found for this image
</p>

<app-action-bar>
<app-dialog-action type="primary" dialogName="image-locations-edit" .dialogParameters=${{ image: this.image }}>
    <app-icon type="add"></app-icon>
    Add some location to this image
</app-dialog-action>
</app-action-bar>
`;

        return html`
<app-action-bar>
<app-dialog-action dialogName="image-locations-edit" .dialogParameters=${{ image: this.image }}>
    <app-icon type="edit"></app-icon>
    Edit image locations
</app-dialog-action>
</app-action-bar>

<app-two-columns>

${repeat(list, item => item.location, item => html`
    <div>${item.name}</div>
`)}

</app-two-columns>
`;
    }

}

