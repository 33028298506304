import assert from "assert";
import { html } from "lit";
import { property } from "lit/decorators.js";
import * as application from "../../../application/index.js";
import { ComponentBase } from "../../base.js";

export class OrganizationHeaderComponent extends ComponentBase {

    @property({ type: String, reflect: true })
    organization?: string;

    render() {
        const { organization } = this;
        assert(organization);

        const route = application.getRoute();
        assert(route);

        return html`
<app-action-bar>
    <app-route-action 
        type="tertiary"
        replace 
        ?active=${route.name === "organization-detail"} 
        routeName="organization-detail"
        .routeParameters=${{ organization }}
    >
    <app-icon type="description"></app-icon>
    Detail
    </app-route-action>
    
    <app-route-action
        type="tertiary"
        replace
        ?active=${route.name === "organization-api-keys"} 
        routeName="organization-api-keys"
        .routeParameters=${{ organization }}
    >
    <app-icon type="library_books"></app-icon>
    Api-keys
    </app-route-action>

    <app-route-action
        type="tertiary"
        replace
        ?active=${route.name === "organization-sessions"} 
        routeName="organization-sessions"
        .routeParameters=${{ organization }}
    >
    <app-icon type="library_books"></app-icon>
    Sessions
    </app-route-action>

    <app-route-action
        type="tertiary"
        replace
        ?active=${route.name === "organization-images"} 
        routeName="organization-images"
        .routeParameters=${{ organization }}
    >
    <app-icon type="library_books"></app-icon>
    Images
    </app-route-action>

    <app-route-action
    type="tertiary"
    replace
    ?active=${route.name === "organization-locations"} 
    routeName="organization-locations"
    .routeParameters=${{ organization }}
    >
    <app-icon type="library_books"></app-icon>
    Locations
</app-route-action>
</app-action-bar>

`;
    }
}
