import assert from "assert";
import { QueryMemoizer } from "queries-kit";

const argCountMap = new WeakMap<QueryMemoizer<
    any, any, any
>, number>();

export function getQueryArgCount<S, E, A extends unknown[]>(
    memoizer: QueryMemoizer<
        S, E, A
    >,
) {
    const result = argCountMap.get(memoizer);
    assert(result != null, "query memoizer not foudn");
    return result;
}

export function setQueryArgCount<S, E, A extends unknown[]>(
    memoizer: QueryMemoizer<
        S, E, A
    >,
    value: number,
) {
    argCountMap.set(memoizer, value);
}
