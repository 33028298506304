import * as index from "./index.js";

declare global {
    interface HTMLElementTagNameMap {
        "app-region-add-dialog": index.RegionAddDialog;
        "app-region-edit-dialog": index.RegionEditDialog;
        "app-region-delete-dialog": index.RegionDeleteDialog;
    }
}

customElements.define("app-region-add-dialog", index.RegionAddDialog);
customElements.define("app-region-edit-dialog", index.RegionEditDialog);
customElements.define("app-region-delete-dialog", index.RegionDeleteDialog);
