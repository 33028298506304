import * as index from "./index.js";

declare global {
    interface HTMLElementTagNameMap {
        "app-layout": index.LayoutComponent;
        "app-dialog": index.DialogComponent;
    }
}

customElements.define("app-layout", index.LayoutComponent);
customElements.define("app-dialog", index.DialogComponent);

