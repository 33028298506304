import assert from "assert";
import { html } from "lit";
import { property } from "lit/decorators.js";
import { QueryStateController } from "../../../controllers/index.js";
import * as q from "../../../queries/index.js";
import { formatInterval } from "../../../utils/interval.js";
import { ComponentBase } from "../../base.js";

export class ImageDetailComponent extends ComponentBase {

    @property({ type: String, reflect: true })
    image?: string

    private queryState = new QueryStateController(
        this,
        applicationContext.queries.image,
        applicationSettings.linger,
        () => {
            return [] as const;
        },
    )

    render() {
        const imageState = this.queryState.state;

        if (!imageState) return html`<app-loading></app-loading>`;

        assert(this.image);

        const item = q.selectImageItem(imageState, this.image);
        if (item == null) return html`<p>Not found!</p>`;

        return html`

<app-action-bar>
    <app-dialog-action dialogName="image-edit" .dialogParameters=${{ image: item.image }}>
        <app-icon type="edit"></app-icon>
        Edit this image
    </app-dialog-action>
</app-action-bar>

<dl>
    <dt>ID</dt>
    <dd>${item.image}</dd>

    <dt>Name</dt>
    <dd>${item.name}</dd>

    <dt>Registry</dt>
    <dd>${item.registry}</dd>

    <dt>Repository</dt>
    <dd>${item.repository}</dd>

    <dt>Overcapacity</dt>
    <dd><pre>${JSON.stringify(item.overcapacity, undefined, 2)}</pre></dd>

    <dt>Resources</dt>
    <dd><pre>${JSON.stringify(item.resources, undefined, 2)}</pre></dd>

    <dt>Ports</dt>
    <dd><pre>${JSON.stringify(item.ports, undefined, 2)}</pre></dd>

    <dt>Image Ready Interval</dt>
    <dd><pre>${formatInterval(item.imageReadyInterval ?? 0)}</pre></dd>

    <dt>Lookback Interval</dt>
    <dd><pre>${formatInterval(item.lookbackInterval ?? 0)}</pre></dd>

    <dt>Metrics path</dt>
    <dd><pre>${item.metricsPath}</pre></dd>

    <dt>Port forwarding</dt>
    <dd><pre>${item.portForwarding}</pre></dd>

</dl>
`;
    }
}

