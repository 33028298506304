import * as index from "./index.js";

declare global {
    interface HTMLElementTagNameMap {
        "app-text-field": index.TextField;
        "app-number-field": index.NumberField;
        "app-json-field": index.JsonField;
        "app-boolean-field": index.BooleanField;
        "app-select-field": index.SelectField;
        "app-interval-field": index.IntervalField;
    }

}

customElements.define("app-text-field", index.TextField);
customElements.define("app-number-field", index.NumberField);
customElements.define("app-json-field", index.JsonField);
customElements.define("app-boolean-field", index.BooleanField);
customElements.define("app-select-field", index.SelectField);
customElements.define("app-interval-field", index.IntervalField);

