import * as index from "./index.js";

declare global {
    interface HTMLElementTagNameMap {
        "app-provider-list-route": index.ProviderListRouteComponent;
    }
}

customElements.define("app-provider-list-route", index.ProviderListRouteComponent);

