import { html } from "lit";
import { property } from "lit/decorators.js";
import { InputFieldBase } from "./input-field-base.js";

export class TextField extends InputFieldBase<string> {

    @property({ type: Boolean, reflect: true })
    multiline = false;

    @property({ type: Boolean, reflect: true })
    trim = false;

    @property({ type: String })
    value?: string;

    renderInput(value: string) {
        const { required, multiline } = this;

        if (multiline) {
            return html`<textarea .value=${value} ?required=${required}></textarea>`;
        }
        else {
            return html`<input type="text" .value=${value} ?required=${required} />`;
        }
    }

    parseValue(value: string) {
        const { trim } = this;

        if (trim) {
            if (value.trim() === "") return undefined;
        }

        if (value === "") return undefined;

        return value;
    }

    formatValue(value: string | undefined) {
        return value ?? "";
    }
}
