import assert from "assert";
import { html } from "lit";
import { property } from "lit/decorators.js";
import { QueryStateController } from "../../../controllers/index.js";
import * as q from "../../../queries/index.js";
import { ComponentBase } from "../../base.js";

export class RegionDetailComponent extends ComponentBase {

    @property({ type: String, reflect: true })
    region?: string

    private queryState = new QueryStateController(
        this,
        applicationContext.queries.region,
        applicationSettings.linger,
        () => {
            return [] as const;
        },
    )

    render() {
        const regionState = this.queryState.state;

        if (!regionState) return html`<app-loading></app-loading>`;

        assert(this.region);

        const item = q.selectRegionItem(regionState, this.region);
        if (item == null) return html`<p>Not found!</p>`;

        return html`

<app-action-bar>
    <app-dialog-action dialogName="region-edit" .dialogParameters=${{ region: item.region }}>
        <app-icon type="edit"></app-icon>
        Edit this region
    </app-dialog-action>
</app-action-bar>

<dl>
    <dt>ID</dt>
    <dd>${item.region}</dd>

    <dt>Names</dt>
    <dd>${item.names.join(", ")}</dd>

    <dt>Parents</dt>
    <dd>${item.parents.join(", ")}</dd>

    <dt>Lat/Lon</dt>
    <dd>${item.latlon?.join(", ")}</dd>

</dl>
`;
    }
}

