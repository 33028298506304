import assert from "assert";
import { css, html, nothing } from "lit";
import { repeat } from "lit-html/directives/repeat.js";
import { property } from "lit/decorators.js";
import { FieldComponentBase } from "./field-component-base.js";

export class SelectField extends FieldComponentBase<unknown> {

    static styles = [...super.styles, css`
select {
    box-sizing: border-box;
    font: inherit;
    width: 100%;
    margin: 0;
    padding: 0.3em;
    border-width: 1px;
}
`]

    @property({ attribute: false, reflect: true })
    value?: unknown;

    @property({ type: Array, reflect: true })
    options?: Array<[unknown, string]>

    protected renderField(formattedValue: string) {
        if (!this.options) return html`${nothing}`;

        return html`
<select>
    <option></option>
    ${repeat(
            this.options,
            ([value]) => value,
            ([value, label], index) => html`<option ?selected=${value === this.value}>${label}</option>`,
        )}
</select>
`;
    }

    connectedCallback() {
        super.connectedCallback();

        this.renderRoot.addEventListener("change", this.handleChange);
    }

    disconnectedCallback() {
        super.disconnectedCallback();

        this.renderRoot.removeEventListener("change", this.handleChange);
    }

    handleChange = (event: Event) => {
        const target = event.target as HTMLSelectElement;
        try {
            assert(this.options, "expected options");

            [this.value] = this.options[target.selectedIndex - 1];

            const valueEvent = new Event("value", { bubbles: true, composed: true });
            this.dispatchEvent(valueEvent);
        }
        catch (error) {
            const errorEvent = new Event("error", { bubbles: true, composed: true });
            this.dispatchEvent(errorEvent);
        }

        this.touch();
    }

}

