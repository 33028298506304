import { html } from "lit";
import { ComponentBase } from "../../base.js";

export class ImageListRouteComponent extends ComponentBase {

    render() {
        return html`
<h1>Images</h1>
<app-image-list></app-image-list>
`;
    }
}

