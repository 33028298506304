import * as index from "./index.js";

declare global {
    interface HTMLElementTagNameMap {
        "app-location-list-route": index.LocationListRouteComponent;
        "app-location-detail-route": index.LocationDetailRouteComponent;
        "app-location-machines-route": index.LocationMachinesRouteComponent;
        "app-location-images-route": index.LocationImagesRouteComponent;
    }
}

customElements.define("app-location-list-route", index.LocationListRouteComponent);
customElements.define("app-location-detail-route", index.LocationDetailRouteComponent);
customElements.define("app-location-machines-route", index.LocationMachinesRouteComponent);
customElements.define("app-location-images-route", index.LocationImagesRouteComponent);

