import assert from "assert";
import { router } from "./router.js";

export interface NavigationInfo {
    name: string;
    parameters: Record<string, string>;
}

export function dispatchNavigate() {
    const event = new Event(
        "navigate",
        { bubbles: true, composed: true },
    );
    window.dispatchEvent(event);
}

export function stringifyRoute(name: string, parameters: Record<string, string> = {}) {
    const pathname = router.stringifyRoute({ name, parameters });
    assert(pathname);

    return pathname;
}

export function getRoute(): NavigationInfo | undefined {
    const { pathname } = window.location;

    const route = router.parseRoute(pathname);
    if (!route) return;

    const { name, parameters } = route;

    return {
        name, parameters,
    };
}

export function pushRoute(name: string, parameters: Record<string, string> = {}) {
    const state = window.history.state;

    const pathname = stringifyRoute(name, parameters);

    window.history.pushState(
        {
            ...state,
            dialog: null,
        },
        "",
        pathname,
    );

    dispatchNavigate();
}

export function replaceRoute(name: string, parameters: Record<string, string> = {}) {
    const state = window.history.state;

    const pathname = stringifyRoute(name, parameters);

    window.history.replaceState(
        {
            ...state,
            dialog: null,
        },
        "",
        pathname,
    );

    dispatchNavigate();
}

export function getDialog(): NavigationInfo | undefined {
    const state = window.history.state;

    return state?.dialog ?? undefined;
}

export function pushDialog(name: string, parameters: Record<string, string> = {}) {
    const state = window.history.state;

    window.history.pushState(
        {
            ...state,
            dialog: {
                name,
                parameters,
            },
        },
        "",
    );

    dispatchNavigate();
}
