import { html } from "lit";
import { repeat } from "lit/directives/repeat.js";
import { QueryStateController } from "../../../controllers/index.js";
import * as q from "../../../queries/index.js";
import { ComponentBase } from "../../base.js";

export class ImageListComponent extends ComponentBase {

    private queryState = new QueryStateController(
        this,
        applicationContext.queries.image,
        applicationSettings.linger,
        () => {
            return [] as const;
        },
    )

    render() {
        const imageState = this.queryState.state;

        if (!imageState) return html`<app-loading></app-loading>`;

        const list = q.selectImageList(imageState);
        if (list.length === 0) return html`

<p>
    No image found!
</p>

<app-action-bar>
<app-dialog-action type="primary" dialogName="image-create">
    <app-icon type="add"></app-icon>
    Add first image
</app-dialog-action>
</app-action-bar>
`;

        return html`

<app-action-bar>
<app-dialog-action dialogName="image-create">
    <app-icon type="add"></app-icon>
    Add image
</app-dialog-action>
</app-action-bar>

<app-scrollable>

<table>
<thead>
    <tr>
        <th>ID</th>
        <th>Name</th>
        <th>Repository</th>
        <th></th>
    </tr>
</thead>

<tbody>
${repeat(list, item => item.image, item => html`
<tr>
    <td>${item.image}</td>
    <td>${item.name}</td>
    <td>${item.repository}</td>
    <td>
    <app-action-bar inline>
        <app-route-action type="primary" routeName="image-detail" .routeParameters=${{ image: item.image }}>
            <app-icon type="description"></app-icon>
        </app-route-action>

        <app-dialog-action type="secondary" dialogName="image-edit" .dialogParameters=${{ image: item.image }}>
            <app-icon type="edit"></app-icon>
        </app-dialog-action>

        <app-dialog-action type="secondary" dialogName="image-remove" .dialogParameters=${{ image: item.image }}>
            <app-icon type="delete"></app-icon>
        </app-dialog-action>
    </app-action-bar>
    </td>
</tr>
`)}
</tbody>
</table>

</app-scrollable>
`;
    }
}

