import * as index from "./index.js";

declare global {
    interface HTMLElementTagNameMap {
        "app-machine-add-dialog": index.MachineAddDialog;
        "app-machine-provision-dialog": index.MachineProvisionDialog;
        "app-machine-edit-dialog": index.MachineEditDialog;
        "app-machine-remove-dialog": index.MachineRemoveDialog;
    }
}

customElements.define("app-machine-add-dialog", index.MachineAddDialog);
customElements.define("app-machine-provision-dialog", index.MachineProvisionDialog);
customElements.define("app-machine-edit-dialog", index.MachineEditDialog);
customElements.define("app-machine-remove-dialog", index.MachineRemoveDialog);

