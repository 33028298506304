import { html } from "lit";
import { property } from "lit/decorators.js";
import { ComponentBase } from "../../base.js";

export class LocationMachinesRouteComponent extends ComponentBase {

    @property({ type: String, reflect: true })
    location!: string;

    render() {
        return html`
<h1>Location ${this.location} machines</h1>

<app-location-header location=${this.location}></app-location-header>
<app-machine-list location=${this.location}></app-machine-list>
`;
    }
}

