import assert from "assert";
import { html } from "lit";
import { property } from "lit/decorators.js";
import { repeat } from "lit/directives/repeat.js";
import { QueryStateController } from "../../../controllers/index.js";
import { selectSessionLogs } from "../../../queries/session-logs.js";
import { ComponentBase } from "../../base.js";

export class SessionLogsComponent extends ComponentBase {

    @property({ type: String, reflect: true })
    organization?: string

    @property({ type: String, reflect: true })
    session?: string

    private queryState = new QueryStateController(
        this,
        applicationContext.queries.sessionLogs,
        applicationSettings.linger,
        () => {
            assert(this.organization);
            assert(this.session);
            return [this.organization, this.session] as const;
        },
    )

    render() {
        const { organization, session } = this;

        assert(organization);
        assert(session);

        const logsState = this.queryState.state;
        if (!logsState) return html`<app-loading></app-loading>`;

        const logs = selectSessionLogs(logsState);
        if (logs.length === 0) return html`<p> No session logs found!</p>`;

        return html`
<app-scrollable>
    <pre>${repeat(logs, (item, index) => index, item => item.text)}</pre>
</app-scrollable>
`;
    }
}
