import assert from "assert";
import { html } from "lit";
import { property } from "lit/decorators.js";
import { BusyController, QueryValueController } from "../../../controllers/index.js";
import * as q from "../../../queries/index.js";
import { ComponentBase } from "../../base.js";

export class MachineRemoveComponent extends ComponentBase {
    @property({ type: String, reflect: true })
    location!: string

    @property({ type: String, reflect: true })
    machine!: string

    private busy = new BusyController(this);

    private queryValue = new QueryValueController(
        this,
        applicationContext.queries.machine,
        applicationSettings.linger,
        q.selectMachineItem,
        () => [this.location, this.machine] as const,
    );

    render() {
        const { isBusy } = this.busy;

        if (this.queryValue.loading) return html`<app-loading></app-loading>`;

        const item = this.queryValue.value;
        if (item == null) return html`<app-not-found></app-not-found>`;

        return html`
<p>
    Do you really want to remove ${item.machine}?
</p>

<app-action-bar>
<app-clickable-action type="primary" ?disabled=${isBusy} @action=${this.handleSubmit}>
    <app-icon type="thumb_up"></app-icon>
    Ok
</app-clickable-action>
<app-clickable-action type="secondary" ?disabled=${isBusy} @action=${this.handleCancel}>
    <app-icon type="undo"></app-icon>
    Cancel
</app-clickable-action>
</app-action-bar>

`;
    }

    protected handleSubmit = this.busy.wrap(async (event: Event) => {
        const result = await applicationContext.services.backend.machineRemoveCommand({
            parameters: {},
            entity: () => {
                return {
                    machine: {
                        location: this.location,
                        id: this.machine,
                    },
                };
            },
        });

        assert(result.status === 204);

        history.back();
    })

    private handleCancel = async (event: Event) => {
        history.back();
    }

}
