import { html } from "lit";
import { ComponentBase } from "../../base.js";

export class LocationAddDialog extends ComponentBase {

    render() {
        return html`
<h1>Create location</h1>
<app-location-add></app-location-add>
`;
    }

}

