import assert from "assert";
import { html } from "lit";
import { property } from "lit/decorators.js";
import { repeat } from "lit/directives/repeat.js";
import { QueryStateController } from "../../../controllers/index.js";
import * as q from "../../../queries/index.js";
import { ComponentBase } from "../../base.js";

export class ApiKeyListComponent extends ComponentBase {
    @property({ type: String, reflect: true })
    organization?: string

    private queryState = new QueryStateController(
        this,
        applicationContext.queries.apiKey,
        applicationSettings.linger,
        () => {
            assert(this.organization);
            return [this.organization] as const;
        },
    )

    render() {
        const { organization } = this;
        assert(organization);

        const apikeyState = this.queryState.state;

        if (!apikeyState) return html`<app-loading></app-loading>`;

        const list = q.selectApiKeyList(apikeyState);
        if (list.length === 0) return html`
<p>
    No api-key found!
</p>

<app-action-bar>
<app-dialog-action type="primary" dialogName="api-key-add" .dialogParameters=${{ organization }}>
    <app-icon type="add"></app-icon>
    Create your first api-key.
</app-dialog-action>
</app-action-bar>
`;

        return html`
<app-action-bar>
<app-dialog-action dialogName="api-key-add" .dialogParameters=${{ organization }}>
    <app-icon type="add"></app-icon>
    Create api-key
</app-dialog-action>
</app-action-bar>

<app-scrollable>

<table>
<thead>
    <tr>
        <th>Token</th>
        <th>Label</th>
        <th></th>
    </tr>
</thead>

<tbody>
${repeat(list, item => item.token, item => html`
<tr>
    <td>${item.token}</td>
    <td>${item.label}</td>
    <td>
    <app-action-bar inline>
        <app-dialog-action type="secondary" dialogName="api-key-remove" .dialogParameters=${{ organization, token: item.token }}>
            <app-icon type="delete"></app-icon>
        </app-dialog-action>
    </app-action-bar>
    </td>
</tr>
`)}
</tbody>
</table>

</app-scrollable>
`;
    }

}
