import * as adminApi from "@gameye-managed/admin-api-spec";
import assert from "assert";
import immutable from "immutable";
import { createQueryMemoizer, Query, QueryMemoizer } from "queries-kit";
import * as application from "../application/index.js";
import { setQueryArgCount } from "../utils/index.js";

//#region query

export type ApiKeyQueryMemoizer = QueryMemoizer<
    ApiKeyQueryState, ApiKeyQueryEventUnion, [string]
>

export type ApiKeyQuery = Query<ApiKeyQueryState, ApiKeyQueryEventUnion>

export function createApiKeyQueryMemoizer(
    services: application.Services,
    settings: application.Settings,
    onError: (error: unknown) => void,
): ApiKeyQueryMemoizer {
    const memoizer = createQueryMemoizer({
        retryIntervalBase: settings.retryIntervalBase,
        retryIntervalCap: settings.retryIntervalCap,
        initialState,
        reduce,
        source,
        onError,
    });

    setQueryArgCount(memoizer, 1);

    return memoizer;

    async function* source(
        signal: AbortSignal,
        organization: string,
    ) {
        const source = await services.backend.getApiKeys({
            parameters: { organization },
        });
        assert(source.status === 200);
        yield* source.entities(signal);
    }
}

//#endregion

//#region state / events

export type ApiKeyQueryEventUnion =
    adminApi.ApiKeySnapshotEventSchema |
    adminApi.ApiKeyAddedEventSchema |
    adminApi.ApiKeyRemovedEventSchema;

export interface ApiKeyQueryEntity {
    label: string,
}

export interface ApiKeyQueryState {
    entities: immutable.Map<string, ApiKeyQueryEntity>;
}

const initialState: ApiKeyQueryState = {
    entities: immutable.Map(),
};

function reduce(
    state: ApiKeyQueryState,
    event: ApiKeyQueryEventUnion,
): ApiKeyQueryState {
    switch (event.type) {
        case "api-key-snapshot": {
            let { entities } = initialState;
            entities = entities.asMutable();

            for (const apikey of event.payload) {
                const { token, label } = apikey;

                const entity = {
                    label,
                };
                entities.set(token, entity);
            }

            entities.asImmutable();

            return {
                entities,
            };
        }

        case "api-key-added": {
            let { entities } = state;

            const { token, label } = event.payload;
            const entity = {
                label,
            };

            assert(!entities.get(token));
            entities = entities.set(token, entity);

            return {
                ...state,
                entities,
            };
        }

        case "api-key-removed": {
            let { entities } = state;

            const { token } = event.payload;

            assert(entities.has(token));

            entities = entities.delete(token);

            return {
                ...state,
                entities,
            };
        }

    }

}

//#endregion

//#region selectors

export function selectApiKeyList(state: ApiKeyQueryState) {
    return [
        ...state.entities.
            map((value, key) => mapEntity(key, value)).
            sortBy(value => value.token).
            values(),
    ];
}

export function selectApiKeyItem(
    state: ApiKeyQueryState,
    apikey: string,
) {
    const entity = state.entities.get(apikey);
    if (!entity) return;

    return mapEntity(apikey, entity);
}

function mapEntity(token: string, entity: ApiKeyQueryEntity) {
    return {
        token,
        ...entity,
    };
}

//#endregion
