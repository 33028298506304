import * as index from "./index.js";

declare global {
    interface HTMLElementTagNameMap {
        "app-region-list-route": index.RegionListRouteComponent;
        "app-region-detail-route": index.RegionDetailRouteComponent;
    }
}

customElements.define("app-region-list-route", index.RegionListRouteComponent);
customElements.define("app-region-detail-route", index.RegionDetailRouteComponent);

