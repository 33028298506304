import { html } from "lit";
import { property } from "lit/decorators.js";
import { ComponentBase } from "../../base.js";

export class MachineProvisionDialog extends ComponentBase {
    @property({ type: String, reflect: true })
    id!: string;

    @property({ type: String, reflect: true })
    location!: string
    
    render() {
        return html`
<h1>Provision machine</h1>
<app-machine-provision location=${this.location}></app-machine-provision>
`;
    }
}
