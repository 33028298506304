import { css, html, TemplateResult } from "lit";
import { property } from "lit/decorators.js";
import { ComponentBase } from "../../base.js";

export abstract class FieldComponentBase<T = unknown> extends ComponentBase {

    static styles = [css`
.title-wrapper {
    display: flex;
}

.input-wrapper {
    display: block;
    position: relative;
    width: fit-content;
    min-width: 100%;
}

.title {
    display: block;
    flex-direction: column;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.required {
    display: none;
    flex-direction: column;
    flex-grow: 1;
}
:host([required]) .required {
    display: block;
}

.icon {
    box-sizing: border-box;
    display: none;
    position: absolute;
    right: 0;
    top: 0;
    margin: 0;
    padding: 0.3em;
    width: 2em;
    text-align: center;
    border-width: 1px;
}
.icon.valid {
    color: green;
}
.icon.invalid {
    color: red;
}

:host(.touched) {
    padding-right: 2em;
}
:host(.touched) .icon.valid {
    display: inline;
}
:host(.touched) .icon.invalid {
    display: none;
}
:host(.touched[error]) .icon.valid {
    display: none;
}
:host(.touched[error]) .icon.invalid {
    display: inline;
}
`];

    @property({ type: String, reflect: true })
    title = "...";

    @property({ type: Boolean, reflect: true })
    required = false;

    @property({ type: Boolean, reflect: true })
    error = false;

    touch() {
        this.classList.add("touched");
    }

    public abstract value?: T;

    constructor() {
        super();
    }

    connectedCallback() {
        super.connectedCallback();

        this.renderRoot.addEventListener("invalid", this.handleInvalid);
    }

    disconnectedCallback() {
        super.disconnectedCallback();

        this.renderRoot.removeEventListener("invalid", this.handleInvalid);
    }

    render() {
        const { title, value } = this;

        return html`
<label>
    <span class="title-wrapper">
        <span class="title">${title}</span>
        <span class="required">*</span>
    </span>
   
    <span class="input-wrapper">
        ${this.renderField(value)}
        
        <span class="icon valid">✔️</span>
        <span class="icon invalid">❌</span>
    </span>
</label>
`;
    }

    protected abstract renderField(value?: T): TemplateResult<1>

    handleInvalid = (event: Event) => {
        const errorEvent = new Event("error", { bubbles: true, composed: true });
        this.dispatchEvent(errorEvent);
    }

}
