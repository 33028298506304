import * as index from "./index.js";

declare global {
    interface HTMLElementTagNameMap {
        "app-clickable-action": index.ClickableActionComponent;
        "app-linkable-action": index.LinkableActionComponent;
        "app-route-action": index.RouteActionComponent;
        "app-dialog-action": index.DialogActionComponent;
    }

}

customElements.define("app-clickable-action", index.ClickableActionComponent);
customElements.define("app-linkable-action", index.LinkableActionComponent);
customElements.define("app-route-action", index.RouteActionComponent);
customElements.define("app-dialog-action", index.DialogActionComponent);

