import assert from "assert";
import { ReactiveController, ReactiveElement } from "lit";
import { FieldComponentBase } from "../components/molecules/index.js";

export type Errors<T> = Record<keyof T, boolean>;

export class FormController<T> implements ReactiveController {

    public model?: Partial<T>;
    public errors?: Record<keyof T, boolean>;

    constructor(
        private readonly host: ReactiveElement,
        private readonly members: Array<keyof T>,
        private readonly hasError: (model: Partial<T>, member: keyof T) => boolean,
    ) {
        host.addController(this);
    }

    public setModel(model: Partial<T>) {
        this.model = model;
        this.errors = this.getErrors(model);
        this.host.requestUpdate();
    }

    public touch() {
        for (const element of this.host.renderRoot.querySelectorAll("*[data-member]")) {
            if (!(element instanceof FieldComponentBase)) continue;
            element.touch();
        }
    }

    public isValid() {
        return Object.values(this.errors ?? {}).
            every(error => !error);
    }

    private getErrors(
        model: Partial<T> | undefined,
    ): Record<keyof T, boolean> | undefined {
        if (model == null) {
            return;
        }
        const errors = {} as Record<keyof T, boolean>;
        for (const member of this.members) {
            errors[member] = this.hasError(model, member);
        }
        return errors;
    }

    private handleValue = (event: Event) => {
        if (!(event.target instanceof FieldComponentBase)) return;

        const member = event.target.dataset.member as keyof T;

        assert(member);
        assert(this.model);
        assert(this.errors);

        this.model = {
            ...this.model,
            [member]: event.target.value,
        };

        this.errors = {
            ...this.errors,
            [member]: this.hasError(this.model, member),
        };

        this.host.requestUpdate();
    }

    private handleError = (event: Event) => {
        if (!(event.target instanceof FieldComponentBase)) return;

        const member = event.target.dataset.member as keyof T;

        assert(member);
        assert(this.errors);

        this.errors = {
            ...this.errors,
            [member]: true,
        };

        this.host.requestUpdate();
    }

    hostConnected() {
        this.host.renderRoot.addEventListener("value", this.handleValue);
        this.host.renderRoot.addEventListener("error", this.handleError);
    }

    hostDisconnected() {
        this.host.renderRoot.removeEventListener("value", this.handleValue);
        this.host.renderRoot.removeEventListener("error", this.handleError);
    }

}

