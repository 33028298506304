import * as index from "./index.js";

declare global {
    interface HTMLElementTagNameMap {
        "app-organization-list-route": index.OrganizationListRouteComponent;
        "app-organization-detail-route": index.OrganizationDetailRouteComponent;
        "app-organization-api-keys-route": index.OrganizationApiKeysRouteComponent;
        "app-organization-sessions-route": index.OrganizationSessionsRouteComponent;
        "app-organization-images-route": index.OrganizationImagesRouteComponent;
        "app-organization-locations-route": index.OrganizationLocationsRouteComponent;
    }
}

customElements.define("app-organization-list-route", index.OrganizationListRouteComponent);
customElements.define("app-organization-detail-route", index.OrganizationDetailRouteComponent);
customElements.define("app-organization-api-keys-route", index.OrganizationApiKeysRouteComponent);
customElements.define("app-organization-sessions-route", index.OrganizationSessionsRouteComponent);
customElements.define("app-organization-images-route", index.OrganizationImagesRouteComponent);
customElements.define("app-organization-locations-route", index.OrganizationLocationsRouteComponent);

