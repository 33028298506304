import * as index from "./index.js";

declare global {
    interface HTMLElementTagNameMap {
        "app-organization-locations-edit-dialog": index.OrganizationLocationsEditDialog;
    }
}

customElements.define("app-organization-locations-edit-dialog", index.OrganizationLocationsEditDialog);

