import { html } from "lit";
import { property } from "lit/decorators.js";
import { ComponentBase } from "../../base.js";

export class ImageEditDialog extends ComponentBase {
    @property({ type: String, reflect: true })
    image!: string

    render() {
        return html`
<h1>Edit image</h1>
<app-image-edit image=${this.image}></app-image-edit>
`;
    }
}
