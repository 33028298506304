import * as index from "./index.js";

declare global {
    interface HTMLElementTagNameMap {
        "app-tag-add-dialog": index.TagAddDialog;
        "app-tag-remove-dialog": index.TagRemoveDialog;
    }
}

customElements.define("app-tag-add-dialog", index.TagAddDialog);
customElements.define("app-tag-remove-dialog", index.TagRemoveDialog);

