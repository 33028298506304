import { css, html, nothing } from "lit";
import { property } from "lit/decorators.js";
import * as application from "../../application/index.js";
import { ComponentBase } from "../base.js";

export class LayoutComponent extends ComponentBase {
    @property({ type: Boolean, reflect: true })
    menu = false;

    @property({ type: Boolean, reflect: true })
    dialog = false;

    static styles = [...super.styles, css`
aside {
    display: block;
    position: fixed;
    box-sizing: border-box;
    z-index: 1;
    left: calc(var(--side-menu-width) * -1);
    top: 0;
    bottom: 0;
    height: 100%;
    width: var(--side-menu-width);
    border-right: 1px solid var(--border-color);
    background-color: var(--lightest-color);
    box-shadow: 0 0 0s var(--lighter-color);
}

aside nav {
    display: flex;
    flex-direction: column;
    align-items: left;
    gap: var(--padding);
    padding: var(--padding);
}

:host([menu]) aside {
    left: 0;
    box-shadow: 0 0 20px var(--lighter-color);
    transition: left 100ms ease-in, box-shadow 100ms ease-in;
}

main {
    box-sizing: border-box;
    width: 100%;
    padding-bottom: 4em;
}

#top-bar {
    display: flex;
    flex-direction: row;
    gap: var(--padding);
    padding: var(--padding);
    border-bottom: 1px solid var(--border-color);
    background-color: var(--lightest-color);
}

#overlay {
    display: none;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: var(--lighter-color);
    opacity: 0;
}

:host([dialog]) #overlay, :host([menu]) #overlay {
    display: block;
    opacity: 0.5;
    transition: opacity 1s;
}

@media screen and (min-width: 900px) {
    aside {
        left: 0;
        z-index: 0;
        box-shadow: none;
    }

    main {
        padding-left: var(--side-menu-width);
    }

    #show-menu {
        display: none;
    }
}

@media print {
    aside {
        display: none;
    }

    #show-menu {
        display: none;
    }
}

`];

    render() {
        return html`
<main>
    <div id="top-bar">
        <app-clickable-action id="show-menu" data-action="show-menu"><app-icon type="menu"></app-icon></app-clickable-action>
        <app-linkable-action href=${application.stringifyRoute("home")!}>Gameye admin</app-linkable-action>
    </div>

    <slot></slot>
</main>

<aside>
    <nav>
        <app-route-action routeName="home">Home</app-route-action>
        <app-route-action routeName="organization-list">Organizations</app-route-action>
        <app-route-action routeName="provider-list">Providers</app-route-action>
        <app-route-action routeName="location-list">Locations</app-route-action>
        <app-route-action routeName="image-list">Images</app-route-action>
        <app-route-action routeName="region-list">Regions</app-route-action>
    </nav>
</aside>

<div id="overlay"></div>

${this.dialog ? html`<slot name="dialog"></slot>` : nothing}

`;

    }

    connectedCallback() {
        super.connectedCallback();

        this.renderRoot.addEventListener("action", this.handleAction);
        window.addEventListener("popstate", this.handlePopState);
        window.addEventListener("navigate", this.handleNavigate);
        window.addEventListener("resize", this.handleResize);
    }

    disconnectedCallback() {
        super.disconnectedCallback();

        this.renderRoot.removeEventListener("action", this.handleAction);
        window.removeEventListener("popstate", this.handlePopState);
        window.removeEventListener("navigate", this.handleNavigate);
        window.removeEventListener("resize", this.handleResize);
    }

    private handleAction = (event: Event) => {
        const target = event.target as HTMLElement;
        if (target.dataset.action === "show-menu") {
            this.menu = true;
        }
    }

    private handlePopState = () => {
        this.menu = false;
    }

    private handleNavigate = () => {
        this.menu = false;
    }

    private handleResize = () => {
        this.menu = false;
    }

}
