import { css, html } from "lit";
import { property } from "lit/decorators.js";
import { ComponentBase } from "../base.js";

export class ActionBarComponent extends ComponentBase {

    @property({ type: Boolean, reflect: true })
    inline = false

    static styles = [css`
:host {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: var(--padding);
    padding: var(--padding);
}

:host[inline] {
    display: flex;
    gap: 0;
    padding: 0;
}

`];

    render() {
        return html`<slot></slot>`;
    }
}
