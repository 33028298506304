import { html } from "lit";
import { ComponentBase } from "../../base.js";

export class ImageCreateDialog extends ComponentBase {
    render() {
        return html`
<h1>Create image</h1>
<app-image-create></app-image-create>
`;
    }

}
