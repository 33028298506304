import { html } from "lit";
import { property } from "lit/decorators.js";
import { ComponentBase } from "../../base.js";

export class OrganizationApiKeysRouteComponent extends ComponentBase {

    @property({ type: String, reflect: true })
    organization!: string;

    render() {
        return html`
<h1>Organization ${this.organization} api-keys</h1>

<app-organization-header organization=${this.organization}></app-organization-header>
<app-api-key-list organization=${this.organization}></app-api-key-list>
`;
    }
}

