import * as index from "./index.js";

declare global {
    interface HTMLElementTagNameMap {
        "app-region-add": index.RegionAddComponent;
        "app-region-edit": index.RegionEditComponent;
        "app-region-list": index.RegionListComponent;
        "app-region-header": index.RegionHeaderComponent;
        "app-region-detail": index.RegionDetailComponent;
        "app-region-delete": index.RegionDeleteComponent;
    }
}

customElements.define("app-region-add", index.RegionAddComponent);
customElements.define("app-region-edit", index.RegionEditComponent);
customElements.define("app-region-list", index.RegionListComponent);
customElements.define("app-region-header", index.RegionHeaderComponent);
customElements.define("app-region-detail", index.RegionDetailComponent);
customElements.define("app-region-delete", index.RegionDeleteComponent);

