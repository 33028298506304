import * as index from "./index.js";

declare global {
    interface HTMLElementTagNameMap {
        "app-image-list": index.ImageListComponent;
        "app-image-header": index.ImageHeaderComponent;
        "app-image-detail": index.ImageDetailComponent;
        "app-image-remove": index.ImageRemoveComponent;
        "app-image-create": index.ImageCreateComponent;
        "app-image-edit": index.ImageEditComponent;
    }
}

customElements.define("app-image-list", index.ImageListComponent);
customElements.define("app-image-header", index.ImageHeaderComponent);
customElements.define("app-image-detail", index.ImageDetailComponent);
customElements.define("app-image-remove", index.ImageRemoveComponent);
customElements.define("app-image-create", index.ImageCreateComponent);
customElements.define("app-image-edit", index.ImageEditComponent);

