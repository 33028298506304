import { html } from "lit";
import { property } from "lit/decorators.js";
import { ComponentBase } from "../../base.js";
export class OrganizationArchiveDialog extends ComponentBase {
    @property({ type: String, reflect: true })
    organization!: string

    render() {
        return html`
<h1>Archive organization</h1>
<app-organization-archive organization=${this.organization}></app-organization-archive>
`;
    }

}

