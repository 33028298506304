import * as adminApi from "@gameye-managed/admin-api-spec";
import assert from "assert";
import immutable from "immutable";
import { createQueryMemoizer, Query, QueryMemoizer } from "queries-kit";
import * as application from "../application/index.js";
import { setQueryArgCount } from "../utils/index.js";

//#region query

export type MachineQueryMemoizer = QueryMemoizer<
    MachineQueryState, MachineQueryEventUnion, [string]
>

export type MachineQuery = Query<MachineQueryState, MachineQueryEventUnion>

export function createMachineQueryMemoizer(
    services: application.Services,
    settings: application.Settings,
    onError: (error: unknown) => void,
): MachineQueryMemoizer {
    const memoizer = createQueryMemoizer({
        retryIntervalBase: settings.retryIntervalBase,
        retryIntervalCap: settings.retryIntervalCap,
        initialState,
        reduce,
        source,
        onError,
    });

    setQueryArgCount(memoizer, 1);

    return memoizer;

    async function* source(
        signal: AbortSignal,
        location: string,
    ) {
        const source = await services.backend.machineQuery({
            parameters: { location },
        });
        assert(source.status === 200);
        yield* source.entities(signal);
    }
}

//#endregion

//#region state / events

export type MachineQueryEventUnion =
    adminApi.MachineSnapshotSchema |
    adminApi.MachineAddedSchema |
    adminApi.MachineDeletedSchema |
    adminApi.MachineUpdatedSchema;

export interface MachineQueryEntity {
    ipV4Address: string;
    providerId: string | undefined;
    available: boolean;
    reachable: boolean;
}

export interface MachineQueryState {
    entities: immutable.Map<string, MachineQueryEntity>;
}

const initialState: MachineQueryState = {
    entities: immutable.Map(),
};

function reduce(
    state: MachineQueryState,
    event: MachineQueryEventUnion,
): MachineQueryState {
    switch (event.type) {
        case "machine-snapshot": {
            let { entities } = initialState;
            entities = entities.asMutable();

            for (const machine of event.payload.machines) {
                const {
                    name,
                    providerId,
                    ipV4Address,
                    available,
                    reachable,
                } = machine;

                const entity = {
                    providerId,
                    ipV4Address,
                    available,
                    reachable,
                };
                entities.set(name, entity);
            }

            entities.asImmutable();

            return {
                entities,
            };
        }

        case "machine-added": {
            let { entities } = state;

            const {
                name,
                providerId,
                ipV4Address,
                available,
                reachable,
            } = event.payload.machine;

            assert(!entities.get(name));
            entities = entities.set(name, {
                providerId,
                ipV4Address,
                available,
                reachable,
            });

            return {
                ...state,
                entities,
            };
        }

        case "machine-deleted": {
            let { entities } = state;

            const { id } = event.payload.machine;

            assert(entities.has(id));

            entities = entities.delete(id);

            return {
                ...state,
                entities,
            };
        }

        case "machine-updated": {
            let { entities } = state;

            const {
                name,
                providerId,
                ipV4Address,
                available,
                reachable,
            } = event.payload.machine;

            const entity = entities.get(name);
            assert(entity);

            entities = entities.set(name, {
                ...entity,

                providerId,
                ipV4Address,
                available,
                reachable,
            });

            return {
                ...state,
                entities,
            };
        }
    }

}

//#endregion

//#region selectors

export function selectMachineList(state: MachineQueryState) {
    return [
        ...state.entities.
            map((value, key) => mapEntity(key, value)).
            sortBy(value => value.machine).
            values(),
    ];
}

export function selectMachineItem(
    state: MachineQueryState,
    machine: string,
) {
    const entity = state.entities.get(machine);
    if (!entity) return;

    return mapEntity(machine, entity);
}

function mapEntity(machine: string, entity: MachineQueryEntity) {
    return {
        machine,
        ...entity,
    };
}

//#endregion
