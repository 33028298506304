import assert from "assert";
import { html } from "lit";
import { property } from "lit/decorators.js";
import * as application from "../../../application/index.js";
import { ComponentBase } from "../../base.js";

export class ImageHeaderComponent extends ComponentBase {

    @property({ type: String, reflect: true })
    image?: string;

    render() {
        const { image } = this;
        assert(image);

        const route = application.getRoute();
        assert(route);

        return html`
<app-action-bar>
    <app-route-action
        type="tertiary"
        replace 
        ?active=${route.name === "image-detail"} 
        routeName="image-detail"
        .routeParameters=${{ image }}
    >
    <app-icon type="description"></app-icon>
    Detail
    </app-route-action>

    <app-route-action
        type="tertiary"
        replace
        ?active=${route.name === "image-tags"} 
        routeName="image-tags"
        .routeParameters=${{ image }}
    >
    <app-icon type="library_books"></app-icon>
    Tags
    </app-route-action>

    <app-route-action
        type="tertiary"
        replace
        ?active=${route.name === "image-locations"} 
        routeName="image-locations"
        .routeParameters=${{ image }}
    >
    <app-icon type="library_books"></app-icon>
    Locations
    </app-route-action>
</app-action-bar>

`;
    }
}
