import { createQueries } from "./query.js";
import { createServices } from "./service.js";
import { Settings } from "./settings.js";

export type Context = ReturnType<typeof createContext>;

export function createContext(
    settings: Settings,
    accessToken: string,
    onError: (error: unknown) => void,
) {
    const services = createServices(settings, accessToken);
    const queries = createQueries(settings, services, onError);

    return {
        services,
        queries,
    };
}
