import { html } from "lit";
import { property } from "lit/decorators.js";
import { ComponentBase } from "../../base.js";

export class SessionStopDialog extends ComponentBase {
    @property({ type: String, reflect: true })
    organization!: string

    @property({ type: String, reflect: true })
    session!: string

    render() {
        return html`
<h1>Stop session</h1>
<app-session-stop organization=${this.organization} session=${this.session}></app-session-stop>
`;
    }

}
