import * as index from "./index.js";

declare global {
    interface HTMLElementTagNameMap {
        "app-location-image-list": index.LocationImageListComponent;
        "app-location-images-edit": index.LocationImagesEditComponent;
    }
}

customElements.define("app-location-image-list", index.LocationImageListComponent);
customElements.define("app-location-images-edit", index.LocationImagesEditComponent);

