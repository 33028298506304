import assert from "assert";
import { ReactiveController, ReactiveElement } from "lit";
import { Promisable } from "type-fest";

export class BusyController implements ReactiveController {

    private busyCount = 0;

    public get isBusy() {
        return this.busyCount > 0;
    }

    constructor(
        private readonly host: ReactiveElement,
    ) {
        host.addController(this);
    }

    hostConnected() {
        this.host.addEventListener("busy-begin", this.handleBusyBegin);
        this.host.addEventListener("busy-end", this.handleBusyEnd);
    }

    hostDisconnected() {
        this.host.removeEventListener("busy-begin", this.handleBusyBegin);
        this.host.removeEventListener("busy-end", this.handleBusyEnd);
    }

    with = async<T>(task: Promisable<T>) => {
        this.dispatchBusyBegin();
        try {
            const result = await task;
            return result;
        }
        finally {
            this.dispatchBusyEnd();
        }
    }

    wrap<A extends unknown[], R>(fn: (...args: A) => Promisable<R>) {
        return (...args: A) => {
            return this.with(fn(...args));
        };
    }

    private dispatchBusyBegin() {
        const event = new Event(
            "busy-begin",
            { bubbles: true, composed: true },
        );
        this.host.dispatchEvent(event);
    }

    private dispatchBusyEnd() {
        const event = new Event(
            "busy-end",
            { bubbles: true, composed: true },
        );
        this.host.dispatchEvent(event);
    }

    private handleBusyBegin = () => {
        this.busyCount += 1;
        this.host.requestUpdate();
    }

    private handleBusyEnd = () => {
        this.busyCount -= 1;
        assert(this.busyCount >= 0, "negative busyCount");
        this.host.requestUpdate();
    }

}

