import * as application from "./application/index.js";
import "./components/main.js";
import { authorize } from "./utils/authorization.js";

declare global {
    const applicationContext: application.Context;
    const applicationSettings: application.Settings;

    interface Window {
        applicationContext: application.Context
        applicationSettings: application.Settings
    }
}

{
    const onError = (error: unknown) => console.warn(error);
    window.applicationContext = application.createContext(
        window.applicationSettings,
        authorize() ?? "-",
        onError,
    );
}
