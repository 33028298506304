import { html } from "lit";
import { property } from "lit/decorators.js";
import { ComponentBase } from "../../base.js";

export class OrganizationImagesEditDialog extends ComponentBase {
    @property({ type: String, reflect: true })
    organization!: string

    render() {
        return html`
<h1>Edit organization images</h1>
<app-organization-images-edit organization=${this.organization}></app-organization-images-edit>
`;

    }

}

