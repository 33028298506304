import * as adminApi from "@gameye-managed/admin-api-spec";
import assert from "assert";
import { html, nothing, PropertyValues } from "lit";
import { property } from "lit/decorators.js";
import { v4 as uuidv4 } from "uuid";
import { BusyController, FormController } from "../../../controllers/index.js";
import { FieldValidators, isIterableEmpty } from "../../../utils/index.js";
import { ComponentBase } from "../../base.js";

type Model =
    adminApi.MachineProvisionCommandApplicationJsonRequestBodyMachineSchema

const members = [
    "id",
] as Array<keyof Model>;

const fieldValidators = {
    ...adminApi.validateMachineAddCommandApplicationJsonRequestBodyMachineSchema.properties,
} as FieldValidators<Model>;

function hasError(model: Partial<Model>, member: keyof Model): boolean {
    const fieldValidator = fieldValidators[member];
    return model[member] != null && !isIterableEmpty(fieldValidator(model[member]));
}

export class MachineProvisionComponent extends ComponentBase {
    @property({ type: String, reflect: true })
    location!: string

    private busy = new BusyController(this);

    private form = new FormController<Model>(
        this,
        members,
        hasError,
    )

    protected getModel() {
        return {
            id: uuidv4(),
        };
    }

    render() {
        const { isBusy } = this.busy;
        const { model, errors } = this.form;

        if (model == null || errors == null) return nothing;

        return html`

        <fieldset>

        <app-text-field
            title="ID"
            data-member="id"
            .value=${model.id}
            ?error=${errors.id}
            trim
            required
        ></app-text-field>
        
        </fieldset>
        
        <app-action-bar>
        <app-clickable-action type="primary" ?disabled=${isBusy} @action=${this.handleSubmit}>
            <app-icon type="thumb_up"></app-icon>
            Ok
        </app-clickable-action>
        <app-clickable-action type="secondary" ?disabled=${isBusy} @action=${this.handleCancel}>
            <app-icon type="undo"></app-icon>
            Cancel
        </app-clickable-action>
        </app-action-bar>
        `;
    }

    private handleSubmit = this.busy.wrap(async () => {
        const { model } = this.form;
        assert(model);

        const result = await applicationContext.services.backend.machineProvisionCommand({
            parameters: {},
            entity: () => {
                return {
                    machine: {
                        id: model.id!,
                        location: this.location,
                        available: false,
                        ipv4Address: "provisioning",
                    },
                };
            },
        });

        assert(result.status === 204);

        history.back();
    })

    private handleCancel = async (event: Event) => {
        history.back();
    }

    update(changedProperties: PropertyValues) {
        super.update(changedProperties);

        if (this.form.model != null) return;

        const model = this.getModel();
        this.form.setModel(model);
    }
}
