import * as index from "./index.js";

declare global {
    interface HTMLElementTagNameMap {
        "app-image-location-list": index.ImageLocationListComponent;
        "app-image-locations-edit": index.ImageLocationsEditComponent;
    }
}

customElements.define("app-image-location-list", index.ImageLocationListComponent);
customElements.define("app-image-locations-edit", index.ImageLocationsEditComponent);

