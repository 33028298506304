import { html } from "lit";
import { property } from "lit/decorators.js";
import { InputFieldBase } from "./input-field-base.js";

export class NumberField extends InputFieldBase<number> {

    @property({ type: Number })
    value?: number;

    renderInput(value: string) {
        const { required } = this;

        return html`<input type="text" inputmode="numeric" .value=${value} ?required=${required} />`;
    }

    parseValue(value: string) {
        if (value.trim() === "") return undefined;

        return parseFloat(value);
    }

    formatValue(value: number | undefined) {
        if (value == null) return "";

        return value.toString();
    }
}
