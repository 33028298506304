import * as index from "./index.js";

declare global {
    interface HTMLElementTagNameMap {
        "app-tag-list": index.TagListComponent;
        "app-tag-remove": index.TagRemoveComponent;
        "app-tag-add": index.TagAddComponent;
    }
}

customElements.define("app-tag-list", index.TagListComponent);
customElements.define("app-tag-remove", index.TagRemoveComponent);
customElements.define("app-tag-add", index.TagAddComponent);

