import * as index from "./index.js";

declare global {
    interface HTMLElementTagNameMap {
        "app-location-list": index.LocationListComponent;
        "app-location-header": index.LocationHeaderComponent;
        "app-location-detail": index.LocationDetailComponent;
        "app-location-remove": index.LocationRemoveComponent;
        "app-location-add": index.LocationAddComponent;
        "app-location-edit": index.LocationEditComponent;
    }
}

customElements.define("app-location-list", index.LocationListComponent);
customElements.define("app-location-header", index.LocationHeaderComponent);
customElements.define("app-location-detail", index.LocationDetailComponent);
customElements.define("app-location-remove", index.LocationRemoveComponent);
customElements.define("app-location-add", index.LocationAddComponent);
customElements.define("app-location-edit", index.LocationEditComponent);

