import * as index from "./index.js";

declare global {
    interface HTMLElementTagNameMap {
        "app-organization-create-dialog": index.OrganizationCreateDialog;
        "app-organization-archive-dialog": index.OrganizationArchiveDialog;
        "app-organization-edit-dialog": index.OrganizationEditDialog;
    }
}

customElements.define("app-organization-create-dialog", index.OrganizationCreateDialog);
customElements.define("app-organization-archive-dialog", index.OrganizationArchiveDialog);
customElements.define("app-organization-edit-dialog", index.OrganizationEditDialog);

