import { html } from "lit";
import { property } from "lit/decorators.js";
import { ComponentBase } from "../../base.js";

export class ImageRemoveDialog extends ComponentBase {
    @property({ type: String, reflect: true })
    image!: string

    render() {
        return html`
<h1>Remove image</h1>
<app-image-remove image=${this.image} ></app-image-remove>
`;
    }

}
