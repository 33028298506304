import { html } from "lit";
import { property } from "lit/decorators.js";
import { ComponentBase } from "../../base.js";

export class OrganizationLocationsEditDialog extends ComponentBase {
    @property({ type: String, reflect: true })
    organization!: string

    render() {
        return html`
<h1>Edit organization locations</h1>
<app-organization-locations-edit organization=${this.organization}></app-organization-locations-edit>
`;

    }

}

