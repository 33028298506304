import { html } from "lit";
import { property } from "lit/decorators.js";
import { InputFieldBase } from "./input-field-base.js";

export class JsonField extends InputFieldBase<object> {

    @property({ type: Object })
    value?: object = undefined;

    renderInput(value: string) {
        const { required } = this;

        return html`<textarea .value=${value} ?required=${required}></textarea>`;
    }

    parseValue(value: string) {
        const trimmedValue = value.trim();
        if (!trimmedValue) return undefined;

        const parsedValue = JSON.parse(value);

        return parsedValue;
    }

    formatValue(value?: object) {
        if (!value) return "";

        return JSON.stringify(value, undefined, 2);
    }
}
