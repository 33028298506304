import assert from "assert";
import { html } from "lit";
import { property } from "lit/decorators.js";
import { QueryStateController } from "../../../controllers/index.js";
import { queryValue } from "../../../directives/index.js";
import * as q from "../../../queries/index.js";
import { ComponentBase } from "../../base.js";

export class SessionDetailComponent extends ComponentBase {

    @property({ type: String, reflect: true })
    organization?: string

    @property({ type: String, reflect: true })
    session?: string

    private queryState = new QueryStateController(
        this,
        applicationContext.queries.session,
        applicationSettings.linger,
        () => {
            assert(this.organization);
            return [this.organization] as const;
        },
    )

    render() {
        const sessionState = this.queryState.state;
        if (!sessionState) return html`<app-loading></app-loading>`;

        assert(this.organization);
        assert(this.session);

        const item = q.selectSessionItem(sessionState, this.session);
        if (item == null) return html`<p>Not found!</p>`;

        return html`
<app-action-bar>
    <app-dialog-action ?disabled=${!item.active} dialogName="session-stop" .dialogParameters=${{ organization: this.organization, session: this.session }}>
        <app-icon type="block"></app-icon>
        Stop session
    </app-dialog-action>
</app-action-bar>

<dl>
    <dt>ID</dt>
    <dd>${this.session}</dd>

    <dt>Organization</dt>
    <dd>${this.organization}</dd>
    
    <dt>Image</dt>
    <dd>${queryValue(applicationContext.queries.image, q.selectImageName, item.image)}</dd>

    <dt>Tag</dt>
    <dd>${item.tag}</dd>

    <dt>Enable metrics</dt>
    <dd>${item.enableMetrics}</dd>

    <dt>Region</dt>
    <dd>${queryValue(applicationContext.queries.region, q.selectRegionName, item.region)}</dd>

    <dt>Location</dt>
    <dd>${queryValue(applicationContext.queries.location, q.selectLocationName, item.location)}</dd>

    <dt>Machine</dt>
    <dd>${item.machine}</dd>

    <dt>Active</dt>
    <dd>${item.active ? "Yes" : "No"}</dd>

    <dt>Created (Unix timestamp)</dt>
    <dd>${item.created}</dd>

    <dt>Host</dt>
    <dd>${item.host}</dd>

    <dt>Labels</dt>
    <dd><pre>${JSON.stringify(item.labels, undefined, 2)}</pre></dd>

    <dt>Ports</dt>
    <dd><pre>${JSON.stringify(item.ports, undefined, 2)}</pre></dd>
</dl>
`;
    }
}
