import { html } from "lit";
import { ComponentBase } from "../../base.js";

export class RegionListRouteComponent extends ComponentBase {

    render() {
        return html`
<h1>Regions</h1>
<app-region-list></app-region-list>
`;
    }
}

