import { html } from "lit";
import { property } from "lit/decorators.js";
import { ComponentBase } from "../../base.js";

export class LocationDetailRouteComponent extends ComponentBase {

    @property({ type: String, reflect: true })
    location!: string;

    render() {
        return html`
<h1>Location ${this.location} details</h1>

<app-location-header location=${this.location}></app-location-header>
<app-location-detail location=${this.location}></app-location-detail>
`;
    }
}

