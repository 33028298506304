import assert from "assert";
import { html } from "lit";
import { property } from "lit/decorators.js";
import * as application from "../../../application/index.js";
import { ComponentBase } from "../../base.js";

export class RegionHeaderComponent extends ComponentBase {

    @property({ type: String, reflect: true })
    region?: string;

    render() {
        const { region } = this;
        assert(region);

        const route = application.getRoute();
        assert(route);

        return html`
<app-action-bar>
    <app-route-action
        type="tertiary"
        replace 
        ?active=${route.name === "region-detail"} 
        routeName="region-detail"
        .routeParameters=${{ region }}
    >
    <app-icon type="description"></app-icon>
    Detail
    </app-route-action>
</app-action-bar>

`;
    }
}
