import { html } from "lit";
import { property } from "lit/decorators.js";
import { ComponentBase } from "../../base.js";

export class LocationImagesEditDialog extends ComponentBase {
    @property({ type: String, reflect: true })
    location!: string

    render() {
        return html`
<h1>Edit location images</h1>
<app-location-images-edit location=${this.location}></app-location-images-edit>
`;

    }

}

